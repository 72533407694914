<template>
  <div class="relative flex flex-col px-5 py-1 bg-white">
    <div
      v-if="showLineGuide && level == 0"
      class="absolute left-[2.42rem] top-[3rem] bottom-0 border-l-2 border-gray-300"
    ></div>
    <div
      v-if="showLineGuide && level == 1"
      class="absolute left-[-0.15rem] top-0 h-[1.6rem] rounded-bl-xl w-4 border-l-2 border-b-2 border-gray-300"
    ></div>
    <!-- Post Header -->
    <div class="flex flex-col gap-2">
      <div v-if="!isComment" class="flex-1 py-2">
        <fw-heading size="h4" muted>{{
          post.collection ? post.collection.name : $t(`postType.${post.type}`)
        }}</fw-heading>
      </div>
      <div v-if="post.title">
        <fw-heading size="4xl">{{ post.title }}</fw-heading>
      </div>
      <div v-if="canEdit && post.status == 'draft'" class="mb-3">
        <fw-tag type="light-orange" size="base">Rascunho</fw-tag>
      </div>
      <div
        class="flex gap-3 justify-between"
        :class="{
          'mt-3': !isComment
        }"
      >
        <fw-person :person="users[post.user_key]" :size="isComment ? 'xs' : 'sm'" paddingless>
          <template #secondline>
            <div class="text-gray-500 font-semibold text-sm">
              {{ post.updated_date || post.created_date | humanDateTimePT }}
            </div>
          </template>
        </fw-person>

        <div v-if="post.reactions && isComment" class="flex gap-1 items-center">
          <ButtonEmoji
            v-if="canReact"
            size="xs"
            ref="love"
            emoji="love"
            :number="post.reactions.love.total"
            :user-clicked="post.reactions.love.user_reaction"
            @reaction="reactPost('love')"
          />
          <ButtonEmoji
            v-if="canReact"
            size="xs"
            ref="applause"
            emoji="clap"
            :number="post.reactions.applause.total"
            :user-clicked="post.reactions.applause.user_reaction"
            @reaction="reactPost('applause')"
          />
          <ButtonEmoji
            v-if="canReact"
            ref="like"
            size="xs"
            emoji="thumbs_up"
            :number="post.reactions.like.total"
            :user-clicked="post.reactions.like.user_reaction"
            @reaction="reactPost('like')"
          />
          <ButtonEmoji
            ref="dislike"
            size="xs"
            emoji="dislike"
            :number="post.reactions.dislike.total"
            :user-clicked="post.reactions.dislike.user_reaction"
            @reaction="reactPost('dislike')"
          />
        </div>

        <div v-if="canEdit || canDelete" :class="{ 'absolute top-3 right-3': !isComment }">
          <fw-menu-more>
            <b-dropdown-item v-if="canEdit" @click="editPost">Editar</b-dropdown-item>
            <b-dropdown-item
              v-if="post.status == 'published' && post.type == 'discussion'"
              @click="changeStatus('closed')"
            >
              Terminar discussão
            </b-dropdown-item>
            <b-dropdown-item
              v-if="(post.status == 'draft' || post.status == 'closed') && post.type != 'comment'"
              @click="changeStatus('published')"
            >
              Publicar
            </b-dropdown-item>
            <b-dropdown-item v-if="post.status == 'published' && post.type != 'comment'" @click="changeStatus('draft')">
              Despublicar
            </b-dropdown-item>
            <b-dropdown-item v-if="canDelete" @click="deletePost">Eliminar</b-dropdown-item>
          </fw-menu-more>
        </div>
      </div>
    </div>
    <!-- Post Cover -->
    <div v-if="post.cover != null" class="relative my-5">
      <img v-if="coverImage" :src="coverImage" class="w-full" />
      <div v-else-if="coverVideo" class="overflow-hidden rounded-2xl mb-2.5 bg-gray-200 image_direct">
        <video controls class="w-full">
          <source :src="coverVideo" type="video/mp4" />
        </video>
      </div>
    </div>
    <!-- Post Message -->
    <div :class="{ 'mt-5 mb-2': !isComment, 'mt-2 mb-2': isComment }">
      <p
        v-if="!post.deleted_date"
        class="font-normal html-content"
        :class="{ 'text-base': !isComment, 'ml-10': isComment }"
        v-html="post.message"
      ></p>
      <p v-else class="text-gray-500">Eliminado {{ post.deleted_by != post.user_key ? 'por um moderador ' : '' }}</p>
    </div>
    <!-- Post Files -->
    <div v-if="post.files && post.files.length > 0" class="flex flex-col gap-1 my-3">
      <div><fw-label>Ficheiros</fw-label></div>
      <div class="flex flex-col gap-2">
        <RecordFileEntry
          v-for="file in post.files"
          :key="file?.key"
          :can-edit="false"
          :allow-classified="false"
          :file="file"
          :can-download="true"
          :can-remove="false"
          :open-preview-modal="true"
          @download="downloadFile(file)"
        />
      </div>
    </div>
    <!-- Post Comments and Reactions -->
    <div
      v-if="!isComment"
      class="flex gap-5 py-2 text-sm items-center"
      :class="{ 'ml-10': isComment, 'mt-3': !isComment }"
    >
      <div class="flex gap-2 items-center flex-1">
        <div v-if="false && showComments && canComment && type == 'comment'">
          <fw-button icon="message" type="xlight" @click.native="$emit('new-comment')">Responder</fw-button>
        </div>
        <div
          v-if="false && showComments && canComment && type == 'comment'"
          class="bg-gray-300 rounded-full h-1.5 w-1.5"
        />
        <div
          v-if="showComments"
          class="text-gray-500"
          :class="{
            ' hover:text-gray-600': canComment
          }"
        >
          {{ post.comments.total }}
          {{
            type == 'comment'
              ? post.comments.total == 1
                ? 'resposta'
                : 'respostas'
              : post.comments.total == 1
              ? 'comentário'
              : 'comentários'
          }}
        </div>
      </div>
      <div v-if="post.reactions" class="flex gap-2 items-center">
        <ButtonEmoji
          v-if="canReact"
          ref="love"
          emoji="love"
          :number="post.reactions.love.total"
          :user-clicked="post.reactions.love.user_reaction"
          @reaction="reactPost('love')"
        />
        <ButtonEmoji
          v-if="canReact"
          ref="applause"
          emoji="clap"
          :number="post.reactions.applause.total"
          :user-clicked="post.reactions.applause.user_reaction"
          @reaction="reactPost('applause')"
        />
        <ButtonEmoji
          v-if="canReact"
          ref="like"
          emoji="thumbs_up"
          :number="post.reactions.like.total"
          :user-clicked="post.reactions.like.user_reaction"
          @reaction="reactPost('like')"
        />
        <ButtonEmoji
          ref="dislike"
          emoji="dislike"
          :number="post.reactions.dislike.total"
          :user-clicked="post.reactions.dislike.user_reaction"
          @reaction="reactPost('dislike')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonEmoji from '@/fw-modules/fw-core-vue/posts/components/buttons/ButtonEmoji'
import ServicePosts from '@/fw-modules/fw-core-vue/posts/services/ServicePosts'
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
// import BlockPost from './BlockPost'

export default {
  components: {
    ButtonEmoji,
    RecordFileEntry
  },
  props: {
    post: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'post'
    },
    resumeMode: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: false
    },
    users: {
      type: Object,
      required: true
    },
    comments: {
      type: Array,
      default: () => []
    },
    level: {
      type: Number,
      default: 0
    },
    showLineGuide: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    coverImage() {
      return this.post.cover && this.post.cover.type == 'image' ? ServiceStorage.getImageViewUrl(this.post.cover) : null
    },
    coverVideo() {
      return this.post.cover && this.post.cover.type == 'video'
        ? ServiceStorage.getFileViewUrl(this.post.cover, false)
        : null
    },
    debugMode() {
      return localStorage.getItem('fw-debug') == 'true'
    },
    showComments() {
      return this.canComment
    },
    canEdit() {
      return this.post?.validations.can_edit
    },
    canComment() {
      return this.post?.validations.can_comment && this.post.status != 'closed' && this.level === 0
    },
    canDelete() {
      return this.post?.validations.can_delete
    },
    canReact() {
      return this.post?.validations.can_react
    },
    isComment() {
      return this.post.type == 'comment'
    }
  },
  methods: {
    async downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },
    async reactPost(reaction) {
      let reactions = ['love', 'applause', 'like', 'dislike']
      //cancel other reactions
      for (let key of reactions) {
        if (key != reaction && this.$refs[key]) {
          this.$refs[key].cancel()
        }
      }
      try {
        let result = await ServicePosts.reactPost(this.post.key, reaction)
        console.log('reactPost', result)
      } catch (e) {
        this.$refs[reaction].revert()
        this.showError('Ocorreu um erro ao reagir à publicação')
        console.error(e)
      }
    },
    editPost() {
      console.log('editPost', this.post)
      this.$emit('edit', this.post)
    },
    async changeStatus(status = 'published') {
      console.log('changeStatus', status)
      this.post['status'] = status
      try {
        let result = await ServicePosts.updatePost(this.post.key, this.post)
        console.log('publishPost', result)
        let labels = {
          post: {
            published: 'Publicação publicada.',
            draft: 'Publicação despublicada'
          },
          discussion: {
            published: 'Tópico de discussão publicado.',
            draft: 'Tópico de discussão despublicado',
            closed: 'Tópico de discussão fechado'
          }
        }
        this.$buefy.snackbar.open({
          message: labels[this.post.type][status],
          type: 'is-primary',
          position: 'is-top-right',
          duration: 3000
        })
        this.$emit('updated', this.post)
      } catch (e) {
        this.showError('Ocorreu um erro ao publicar a publicação')
        console.error(e)
      }
    },
    deletePost() {
      this.$buefy.dialog.confirm({
        title: this.post.type == 'comment' ? 'Eliminar comentário' : 'Eliminar publicação',
        message:
          this.post.type == 'comment'
            ? 'Tem a certeza que deseja eliminar este comentário?'
            : 'Tem a certeza que deseja eliminar esta publicação?',
        confirmText: 'Eliminar',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: false,
        onConfirm: async () => {
          if (this.post.type == 'comment') {
            console.log('deleteComment', this.post)
            try {
              let result = await ServicePosts.detelePost(this.post.key)
              console.log('deleteComment', result)
              this.$buefy.snackbar.open({
                message: `Comentário removido.`,
                type: 'is-primary',
                position: 'is-top-right',
                duration: 3000
              })
              this.$emit('deleted', result)
            } catch (e) {
              this.showError('Ocorreu um erro ao remover o comentário')
              console.error(e)
            }
          } else {
            console.log('deletePost', this.post)
            try {
              let result = await ServicePosts.detelePost(this.post.key)
              console.log('deletePost', result)
              this.$buefy.snackbar.open({
                message: `Publicação removida.`,
                type: 'is-primary',
                position: 'is-top-right',
                duration: 3000
              })
              this.$emit('deleted', this.post)
            } catch (e) {
              this.showError('Ocorreu um erro ao remover a publicação')
              console.error(e)
            }
          }
        }
      })
    },
    showError(message = 'Ocorreu um erro. Tente de novo mais tarde ou contacte o suporte.') {
      this.$buefy.dialog.alert({
        message: message,
        type: 'is-danger',
        hasIcon: false,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true
      })
    },
    open(comment = false) {
      let result = this.post
      result.comment = comment
      this.$emit('open', result)
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "postType": {
      "post": "Atualização",
      "discussion": "Discussão",
      "comment": "Comentário"
    }
  },
  "en": {
    "postType": {
      "post": "Atualização",
      "discussion": "Discussão",
      "comment": "Comentário"
    }
  }
}
</i18n>
