<template>
  <fw-panel :title="$t('settings')" featured :loading="savingData" after-loading-checked>
    <div class="flex gap-1 flex-col">
      {{ $t('change_visibility') }}
      <div class="flex gap-5">
        <fw-button
          v-if="portfolio.status !== 'public'"
          type="primary"
          :disabled="savingData"
          :loading="savingData"
          @click.native="publish(true)"
          >{{ $t('publish') }}</fw-button
        >
        <fw-button v-else type="primary" :disabled="savingData" :loading="savingData" @click.native="publish(false)">{{
          $t('unpublish')
        }}</fw-button>
      </div>
      <div v-if="portfolio.status == 'public'" class="text-sm  text-gray-500">
        {{ $t('public_message') }}
      </div>
      <div v-if="portfolio.status == 'public'" class="has-margin-top-medium">
        <div class="label is-marginless">{{ $t('portfolio_link') }}</div>
        <div class="is-flex is-flex-align-center">
          <a target="_blank" :href="url" class="has-margin-right-small">
            <v-clamp autoresize :max-lines="1">{{ url }}</v-clamp>
          </a>
          <b-button type="is-light is-small" @click="toClipboardVideoUrl(url)">{{ $t('copy_link') }}</b-button>
        </div>
      </div>
      <div class="label is-marginless">{{ $t('multilanguage') }}</div>
      <div class="flex">
        <b-checkbox v-model="portfolio.options.hasEnglish" @input="savePortfolio()"> </b-checkbox>
        {{ $t('english_version') }}
      </div>
      <div class="label is-marginless">{{ $t('permissions') }}</div>
      <div class="flex">
        <b-checkbox v-model="portfolio.options.allowEmail" @input="savePortfolio()"> </b-checkbox>
        {{ $t('email') }}
      </div>
      <div class="flex">
        <b-checkbox v-model="portfolio.options.allowLive" @input="savePortfolio()"> </b-checkbox>
        {{ $t('live') }}
      </div>
    </div>
  </fw-panel>
</template>
<script>
export default {
  name: 'PanelManagePortfolioSettings',
  props: {
    portfolio: {
      type: Object,
      default: () => {}
    },
    savingData: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    environment() {
      return process.env.VUE_APP_ENV || process.env.NODE_ENV
    },
    url() {
      return (
        (this.environment == 'development'
          ? 'https://ucstudent.dev.ucframework.pt/portfolio/'
          : 'https://ucstudent.uc.pt/portfolio/') + this.portfolio.user_key
      )
    }
  },
  methods: {
    savePortfolio() {
      let portfolio = JSON.parse(JSON.stringify(this.portfolio))
      //replace competences by key
      portfolio.academic_plan.items.forEach((item, i) => {
        item.curriculum.forEach((curriculum, c) => {
          portfolio.academic_plan.items[i].curriculum[c].competences.forEach((competence, cp) => {
            portfolio.academic_plan.items[i].curriculum[c].competences[cp] = competence.key
          })
        })

        item.extra_curriculum.forEach((extra_curriculum, c) => {
          portfolio.academic_plan.items[i].extra_curriculum[c].competences.forEach((competence, cp) => {
            portfolio.academic_plan.items[i].extra_curriculum[c].competences[cp] = competence.key
          })
        })
      })
      portfolio.carrer_development.professional_experience = portfolio.carrer_development.professional_experience.map(
        item => {
          item.competences = item.competences.map(competence => competence.key)
          return item
        }
      )
      portfolio.carrer_development.volunteering = portfolio.carrer_development.volunteering.map(item => {
        item.competences = item.competences.map(competence => competence.key)
        return item
      })
      portfolio.carrer_development.certifications = portfolio.carrer_development.certifications.map(item => {
        item.competences = item.competences.map(competence => competence.key)
        return item
      })

      this.$emit('save-portfolio', portfolio)
    },
    toClipboardVideoUrl(videoUrl) {
      this.$copyText(videoUrl).then(() => {
        this.$buefy.toast.open({
          duration: 3000,
          message: `Endereço copiado`,
          position: 'is-top'
        })
      })
    },
    publish(publish = true) {
      this.$buefy.dialog.confirm({
        title: publish ? `Publicar portfolio` : 'Despublicar portfolio',
        cancelText: 'Cancelar',
        confirmText: publish ? 'Publicar' : 'Despublicar',
        message: publish
          ? `Tem a certeza que deseja publicar este portfolio?`
          : 'Tem a certeza que deseja despublicar este portfolio?',
        onConfirm: () => {
          this.$emit('publish-portfolio', publish)
        }
      })
    }
  }
}
</script>

<i18n>
    {
      "pt": {
        "permissions": "Permissões",
        "email": "Permitir que me contactem por e-mail",
        "live": "Permitir que acedam à minha sala virtual",
        "settings": "Configurações",
        "change_visibility": "Mudar visibilidade do portfolio",
        "publish": "Publicar portfolio",
        "unpublish": "Despublicar portfolio",
        "public_message": "O portfolio fica acessível a qualquer pessoa com quem partilhe o link.",
        "copy_link": "Copiar link",
        "portfolio_link": "Link do portfolio",
        "multilanguage": "Portfolio multi-idioma",
        "english_version": "Versão em Inglês"
      },
      "en": {
        "permissions": "Permissions",
        "email": "Allow people to contact me by email",
        "live": "Allow people to access my virtual room",
        "settings": "Settings",
        "change_visibility": "Change portfolio visibility",
        "publish": "Publish portfolio",
        "unpublish": "Unpublish portfolio",
        "public_message": "The portfolio is accessible to anyone you share the link with.",
        "copy_link": "Copy link",
        "portfolio_link": "Portfolio link",
        "multilanguage": "Multilanguage portfolio",
        "english_version": "English version"
      }
    }
</i18n>
