<template>
  <fw-modal v-if="showModal" :active.sync="showModal" :can-cancel="true" size="xl" @close="showModal = false">
    <template #default>
      <div class="flex flex-col gap-3 text-left">
        <fw-heading size="h3">{{ $t('title') }}</fw-heading>
        <div class=" min-h-64 max-h-96 overflow-y-auto p-2">
          <div class="flex flex-wrap gap-3">
            <div
              v-for="(bk, b) in backgroundOptions"
              :key="'background' + b"
              class="rounded-md overflow-hidden cursor-pointer border border-gray-200 hover:border-primary h-24 w-40"
              :class="{ 'ring-primary ring-2': bk == selectedImage }"
              :style="{ backgroundImage: 'url(' + bkimgMap[bk] + ')' }"
              @click="selectedImage = bk"
            ></div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <fw-button type="black" :loading="loading" expanded @click.native="save()">{{ $t('save') }}</fw-button>
    </template>
  </fw-modal>
</template>

<script>
export default {
  name: 'ModalCoverImage',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedImage: 'abstract-01',
      customImage: null,
      showModal: false,
      bkimgMap: {
        'abstract-01': 'https://static.fw.uc.pt/banners/abstract-01.jpg',
        'abstract-02': 'https://static.fw.uc.pt/banners/abstract-02.jpg',
        'abstract-03': 'https://static.fw.uc.pt/banners/abstract-03.jpg'
      }
    }
  },
  computed: {
    backgroundOptions() {
      return Object.keys(this.bkimgMap)
    }
  },
  methods: {
    edit(data) {
      if (this.bkimgMap[data] != null) {
        this.selectedImage = data
      } else {
        this.customImage = data
      }
      this.show()
    },
    save() {
      this.$emit('save', this.customImage != null ? this.this.customImage : this.selectedImage)
      this.reset()
    },
    show() {
      this.showModal = true
    },
    reset() {
      this.selectedImage = 'abstract-01'
      this.customImage = null
      this.showModal = false
    }
  }
}
</script>

<i18n>
    {
      "pt": {
        "title": "Alterar imagem de capa",
        "save": "Guardar",
        "label": {
          "pt": " em Português",
          "en": " em Inglês",
          "required": "Insira texto em Português e Inglês."
        }
      },
      "en": {
        "title": "Change cover image",
        "save": "Save",
        "label": {
          "pt": " in Portuguese",
          "en": " in English",
          "required": "Insert text in Portuguese and English."
        }
      }
    }
</i18n>
