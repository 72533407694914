<template>
  <div
    class="relative flex flex-col gap-1 bg-white"
    :class="{
      'px-5 pt-3 pb-2': !paddingless
    }"
  >
    <!-- Post Header -->
    <div class="flex flex-col gap-2">
      <div class="flex items-center">
        <fw-heading class="flex-1" size="h4" muted>{{
          post.collection ? post.collection.name : $t(`postType.${post.type}`)
        }}</fw-heading>
        <div class="text-gray-500 font-semibold text-sm">
          {{ post.updated_date || post.created_date | humanDateTimePT }}
        </div>
      </div>
      <div class="flex gap-3">
        <fw-icon-chat-thread v-if="type == 'discussion'" class="w-10 h-10 flex-shrink-0" />
        <fw-heading v-if="post.title" size="2xl" class="flex-1" :class="{ 'pt-1': type == 'discussion' }">{{
          post.title
        }}</fw-heading>
      </div>
      <div class="relative">
        <div ref="message" class="max-h-44 text-sm overflow-hidden" v-html="post.message"></div>
        <div
          v-if="isOverflowing"
          class="bg-gradient-to-t pb-2 pt-8 from-white via-white to-transparent absolute left-0 right-0 bottom-0 flex items-center justify-center"
        >
          <div class="bg-gray-100 py-1 px-2.5 rounded-full text-gray-500 text-xs">Clique para ver mais</div>
        </div>
      </div>
      <div v-if="type != 'discussion'" class="text-gray-500">
        <fw-person :person="users[post.user_key]" size="xs" paddingless>
          <template #secondline>
            <div class="text-gray-500 font-semibold text-sm">
              {{ post.updated_date || post.created_date | humanDateTimePT }}
            </div>
          </template>
        </fw-person>
      </div>
    </div>
    <!-- Post Comments -->
    <div class="flex gap-3 justify-between items-center">
      <div class="text-gray-500 text-sm">
        {{ post.comments.total }} {{ post.comments.total == 1 ? 'comentário' : 'comentários' }}
      </div>
      <div class="flex gap-2 items-center">
        <ButtonEmoji ref="love" emoji="love" read-only :number="post.reactions.love.total" />
        <ButtonEmoji ref="applause" emoji="clap" read-only :number="post.reactions.applause.total" />
        <ButtonEmoji ref="like" emoji="thumbs_up" read-only :number="post.reactions.like.total" />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonEmoji from '@/fw-modules/fw-core-vue/posts/components/buttons/ButtonEmoji'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

export default {
  name: 'RecordPost',

  components: {
    ButtonEmoji
  },

  props: {
    post: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'post'
    },
    users: {
      type: Object,
      required: true
    },
    comments: {
      type: Array,
      default: () => []
    },
    paddingless: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isOverflowing: false
    }
  },
  computed: {
    coverImage() {
      return this.post.cover && this.post.cover.type == 'image' ? ServiceStorage.getImageViewUrl(this.post.cover) : null
    }
  },
  mounted() {
    this.checkOverflow()
  },
  methods: {
    checkOverflow() {
      const el = this.$refs.message
      if (el) {
        this.isOverflowing = el.scrollHeight > el.clientHeight
      }
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "postType": {
      "post": "Publicação",
      "discussion": "Discussão",
      "comment": "Comentário"
    }
  },
  "en": {
    "postType": {
      "post": "Publicação",
      "discussion": "Discussão",
      "comment": "Comentário"
    }
  }
}
</i18n>
