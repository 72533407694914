<template>
  <fw-layout :back-to-enable="false" mobile-ready footer>
    <template #main-content>
      <PanelUCIdHero :user="user" version="v2" class="mt-4 mb-10" :stats="stats" />
      <fw-panel title="Acesso rápido">
        <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 justify-center mb-10 items-stretch">
          <button-quick-access
            v-if="meeting && meeting.key"
            :label="meeting.running ? 'Sala aberta' : 'Sala fechada'"
            title="Sala Pessoal"
            :footer="meeting.key_alias"
            @clicked="$router.push({ name: 'live', params: { key: meeting.key_alias } })"
          />
          <button-quick-access-enter-room />
          <button-quick-access
            label="Lecionação"
            title="Disciplinas e turmas"
            @clicked="$router.push({ name: 'units' })"
          />
          <button-quick-access label="Recursos" title="Ficheiros" @clicked="$router.push({ name: 'buckets' })" />
          <button-quick-access-enter-exam v-if="false" />
          <button-quick-access label="Comunicação" title="Grupos" @clicked="$router.push({ name: 'groups' })" />
          <button-quick-access label="Comunicação" title="Canais" @clicked="$router.push({ name: 'chats' })" />
          <button-quick-access
            v-if="betaMode"
            label="Recursos"
            title="e-Portfólio"
            @clicked="$router.push({ name: 'portfolio', params: { key: user.key } })"
          />
        </div>
      </fw-panel>

      <!-- Welcome -->
      <fw-panel v-if="false" title="A UC DÁ-TE AS BOAS-VINDAS!" class="mt-5 mb-10">
        <div class="flex flex-col gap-5">
          <div>
            <div class="hidden">
              <img
                src="https://estudarnauc.uc.pt/site/assets/files/1218104/semana-integrac_a_o-banner-url-1280x525-1.1800x0.png"
                class="w-full rounded-xl overflow-hidden"
              />
            </div>
            <div class="text-3xl font-black">
              <div class="uppercase text-gray-800">Semana de Acolhimento e Integração</div>
              <div class="uppercase" style="color: #46a8e5">11 a 15 de setembro</div>
            </div>
            <div class="text-sm font-medium border-b mb-2 mt-1 md:mt-2 pb-3">
              <div class="max-w-xs md:max-w-none text-gray-500">
                Visita o nosso site para saber tudo sobre o que preparámos para ti.
              </div>
              <div>
                <a href="https://estudarnauc.uc.pt/boas-vindas" target="_blank" class="font-bold">Saber mais</a>
              </div>
            </div>
          </div>
          <div class="font-bold text-gray-400 text-xs uppercase -mb-2">Programa completo</div>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-3 lg:gap-4">
            <div v-for="day in welcomeWeek" :key="day.date" class="flex flex-col flex-1 gap-2">
              <div>
                <div class="font-bold text-sm uppercase" style="color: #46a8e5">{{ day.day }}</div>
                <div class="font-semibold text-sm">{{ day.date }}</div>
              </div>
              <div class="flex flex-col gap-3 flex-1">
                <div
                  v-for="event in day.events"
                  :key="event.key"
                  class="flex flex-col gap-1 px-3 py-2 rounded-xl opacity-80"
                  style="background-color: #dfdecc"
                >
                  <div class="flex-shrink-0 flex gap-2 items-center w-full font-semibold">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="fill-current opacity-60 w-5 h-5"
                      data-v-4f4c5135=""
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8h4v2h-6V7h2v5z"
                      ></path>
                    </svg>
                    <span class="text-sm">{{ event.time }}</span>
                  </div>
                  <div class="min-w-0 flex flex-col gap-1">
                    <div class="font-bold leading-5 text-base">{{ event.title }}</div>
                    <div class="font-semibold text-xs">{{ event.description }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <div class="font-black uppercase text-gray-800">
              Não percas!
            </div>
            <div>
              <a href="https://estudarnauc.uc.pt/boas-vindas" target="_blank">
                <img
                  src="https://estudarnauc.uc.pt/site/assets/files/1218682/banner_concerto-lp_v1.1800x0.png"
                  class="w-full rounded-xl overflow-hidden"
                />
              </a>
            </div>
          </div>
        </div>
      </fw-panel>

      <PanelNextClasses profile="student" class="my-5" />
      <PanelCoursesTransitionOldVersion
        v-if="hasCourses || hasNonDegreeCourses"
        :loading="loadingEnrolledCourses || loadingEnrolledNDCourses"
        title="Os meus cursos"
        :courses="enrolledCourses"
        :old-courses="enrolledNonDegreeCourses"
      />
    </template>
  </fw-layout>
</template>

<script>
import ButtonQuickAccess from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonQuickAccess'
import ButtonQuickAccessEnterRoom from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonQuickAccessEnterRoom'
import ButtonQuickAccessEnterExam from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonQuickAccessEnterExam'
import ServiceSettings from '@/fw-modules/fw-core-vue/id/services/ServiceSettings'
import PanelUCIdHero from '@/fw-modules/fw-core-vue/id/components/panels/PanelUCIdHero'
import PanelNextClasses from '@/fw-modules/fw-core-vue/academic/components/panels/PanelNextClasses'
import ServiceAcademic from '@/fw-modules/fw-core-vue/academic/services/ServiceAcademic'
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'
import utils from '@/fw-modules/fw-core-vue/utilities/utils.js'
import ServiceCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import PanelCoursesTransitionOldVersion from '@/fw-modules/fw-core-vue/courses/components/panels/PanelCoursesTransitionOldVersion'
export default {
  components: {
    ButtonQuickAccessEnterRoom,
    ButtonQuickAccessEnterExam,
    PanelUCIdHero,
    ButtonQuickAccess,
    PanelNextClasses,
    PanelCoursesTransitionOldVersion
  },

  data() {
    this.loadStats()
    let debug = Boolean(localStorage.getItem('fw-debug'))
    // this.loadBLCourses()
    // this.loadNondegreeCourses()
    this.loadEnrolledNonDegreeCourses()
    this.loadEnrolledCourses()

    return {
      openIntroModal: false,
      debugMode: debug,
      blCoursesIds: [],
      enrolledNonDegreeCourses: [],
      nondegreeCourses: [],
      enrolledCourses: [],
      loadingEnrolledCourses: false,
      loadingEnrolledNDCourses: false,

      stats: [
        {
          key: 'academic-units',
          label: 'Disciplinas',
          value: '0',
          valueSuffix: null,
          featured: false
        },
        {
          key: 'academic-classes',
          label: 'Turmas',
          value: '0',
          valueSuffix: null,
          featured: false
        },
        {
          key: 'meetings-meetings',
          label: 'Sessões virtuais',
          value: '0',
          featured: false,
          valueSuffix: null,
          description: 'Via UC St / Mt'
        },
        {
          key: 'meetings-hours',
          label: 'Horas virtuais',
          value: '0',
          featured: false,
          valueSuffix: 'h',
          description: 'Sessões virtuais na UC St / Mt'
        }
      ],

      welcomeWeek: [
        {
          day: 'Segunda-feira',
          date: '2023-09-11',
          events: [
            {
              title: 'Atividades nas Faculdades e Departamentos',
              description: 'Os horários e locais deverão ser confirmados junto das Faculdades e Departamentos!',
              time: '09:30 - 13:00'
            },
            {
              title: 'Atividades com Núcleos de Estudantes da AAC',
              description: 'Início junto do teu núcleo de estudantes!',
              time: '14:30 - 17:00'
            }
          ]
        },
        {
          day: 'Terça-feira',
          date: '2023-09-12',
          events: [
            {
              title: 'Atividades nas Faculdades e Departamentos',
              description: 'Os horários e locais deverão ser confirmados junto das Faculdades e Departamentos!',
              time: '09:30 - 13:00'
            },
            {
              title: 'Peddy Papper “Conhece a UC, Conhece Coimbra”',
              description: 'Início junto do teu núcleo de estudantes!',
              time: '14:30 - 18:00'
            }
          ]
        },
        {
          day: 'Quarta-feira',
          date: '2023-09-13',
          events: [
            {
              title: 'Fórum "O que quero e posso ser: estudante universitário e cidadão"',
              description: 'Student Hub - Pólo 1',
              time: '09:30 - 13:00'
            },
            {
              title: 'Universo UC: Mostra de serviços, projetos e iniciativas',
              description: 'Rua Larga',
              time: '15:00 - 19:00'
            },
            {
              title: 'Abertura do Ano Letivo com Concerto de Miguel Araújo e abertura da Big Band Rags (TAUC)',
              description: 'Paço das Escolas',
              time: '20:00 - 22:00'
            }
          ]
        },
        {
          day: 'Quinta-feira',
          date: '2023-09-14',
          events: [
            {
              title: 'Atividades nas Faculdades e Departamentos',
              description: 'Os horários e locais deverão ser confirmados junto das Faculdades e Departamentos!',
              time: '09:30 - 13:00'
            },
            {
              title: 'Atividades com Desporto UC',
              description: 'Estádio Universitário',
              time: '14:30 - 18:00'
            }
          ]
        },
        {
          day: 'Sexta-feira',
          date: '2023-09-15',
          events: [
            {
              title: 'Atividades nas Faculdades e Departamentos',
              description: 'Os horários e locais deverão ser confirmados junto das Faculdades e Departamentos!',
              time: '09:30 - 13:00'
            }
          ]
        }
      ],

      isFreshMan: false
    }
  },

  computed: {
    betaMode() {
      return localStorage.getItem('beta') == 'true'
    },
    user() {
      return this.$store.getters.getUser
    },
    meeting() {
      if (this.user && this.user.meeting) return this.user.meeting
      else return null
    },
    hasNonDegreeCourses() {
      return !!this.enrolledNonDegreeCourses.length
    },
    hasCourses() {
      return !!this.enrolledCourses.length
    }
  },

  mounted() {
    this.$store.dispatch('setUser')
    ServiceSettings.checkAppVersion()

    // this.getAcademicUser()
  },

  methods: {
    async loadStats() {
      const user = this.$store.getters.getUser
      if (!user || !user.key) return

      const stats = {}
      if (user.roles.includes('student')) {
        try {
          const academicStats = await ServiceAcademic.getStudentStats()
          for (let [key, value] of Object.entries(academicStats)) {
            stats[`academic-${key}`] = value
          }
        } catch (error) {
          //console.error('Failed to get academic stats', error)
        }
      }

      try {
        const meetingStats = await ServiceMeetings.getStats()
        for (let [key, value] of Object.entries(meetingStats)) {
          if (key == 'attend_seconds') stats['meetings-hours'] = Math.round(value / 60 / 60)
          else stats[`meetings-${key}`] = value
        }
      } catch (error) {
        //console.error('Failed to get meetings stats', error)
      }

      for (const stat of this.stats) {
        const value = stats[stat.key]
        if (value) stat.value = String(value)
        if (stat.key == 'academic-done_sessions') {
          const sessions = stats['academic-sessions']
          if (sessions) stat.description = `Total de ${sessions}`
        }
      }
    },
    async getAcademicUser() {
      const WelcomeWeek23PanelKey = '2023-welcome-week-panel-showed'
      if (localStorage.getItem(WelcomeWeek23PanelKey)) return

      utils.tryAndCatch(this, async () => {
        const academicUser = await ServiceAcademic.getUser()

        if (academicUser && academicUser.is_new_user) {
          this.isFreshMan = true

          // Save something to control
          let showed = await ServiceSettings.getSetting(WelcomeWeek23PanelKey)
          showed = showed ? showed.value : null
          if (!showed) {
            await ServiceSettings.setSetting(WelcomeWeek23PanelKey, true)
            localStorage.setItem(WelcomeWeek23PanelKey, true)
          }
        }
      })
    },
    async loadBLCourses() {
      const courses = await ServiceAcademic.getBLCourses()
      if (courses.length) this.blCoursesIds = courses
    },
    async loadNondegreeCourses() {
      const data = await ServiceAcademic.getCoursesCatalogue()
      if (data.courses.length) this.nondegreeCourses = data.courses
    },
    async loadEnrolledNonDegreeCourses() {
      try {
        this.loadingEnrolledNDCourses = true
        const enrolledNonDegreeCoursesData = await ServiceAcademic.getUserEnrollments()
        this.enrolledNonDegreeCourses = enrolledNonDegreeCoursesData.courses
        this.loadingEnrolledNDCourses = false
      } catch (error) {
        console.error(error)
        this.loadingEnrolledNDCourses = false
      }
    },
    async loadEnrolledCourses() {
      try {
        this.loadingEnrolledCourses = true
        const enrolledCourses = await ServiceCourses.getUserEnrollments()
        console.log('enrolledCourses', enrolledCourses)
        this.enrolledCourses = enrolledCourses.courses
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingEnrolledCourses = false
      }
    }
  }
}
</script>
