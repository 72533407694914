var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fw-layout',{attrs:{"full":"","back-to":"/units","mobile-ready":""},scopedSlots:_vm._u([{key:"main-sidebar",fn:function(){return [_c('SidebarUnit',{attrs:{"unit":_vm.unitEdition,"view":_vm.view},on:{"go-to-view":_vm.goToView}})]},proxy:true},{key:"main-content",fn:function(){return [(_vm.unitEdition)?_c('div',{staticClass:"w-full h-full md:p-5"},[(_vm.view == 'classes')?_c('fw-panel',{attrs:{"title":"Turmas","featured":""}},[_c('PanelUnitClassesList',{attrs:{"unit":_vm.unitEdition,"classes":_vm.classEditions,"profile":"student"}})],1):_vm._e(),(_vm.view == 'buckets')?_c('fw-panel',{attrs:{"title":"Materiais de apoio","featured":""}},[_c('PanelBucketList',{attrs:{"paddingless":"","application-context":{
            application: 'academic',
            item_type: 'unit_edition', //, 'unit_edition'],
            //include_unit_buckets: edition ? (edition.unit ? edition.unit.key : null) : null,
            //item_key: unitEdition.key,
            include_unit_buckets: _vm.unitEdition.unit_key_for_bucket, //UNIT BUCKET KEY
            item_key: _vm.unitEdition.key
          }}})],1):_vm._e()],1):_vm._e()]},proxy:true},{key:"tapbar",fn:function(){return [_c('TapbarUnit',{attrs:{"view":_vm.view},on:{"go-to-view":_vm.goToView}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }