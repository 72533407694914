<template>
  <form
    class="fw-form flex flex-col"
    :style="{ fontSize: computedFontSize }"
    :class="[sectionGap, { text15x: fontSizeRatio > 1.25 && fontSizeRatio < 1.75, text20x: fontSizeRatio >= 1.75 }]"
    @submit.prevent="sendForm"
    @keyup.enter="sendForm"
  >
    <div
      v-for="(section, s) in form"
      :id="id + '_form_section_' + (section[keyName] ? section[keyName] : '')"
      :key="'form_section_' + s"
      class="flex-col relative"
    >
      <div
        v-if="
          (language &&
            typeOf(section.title) !== 'string' &&
            section.title[language] &&
            section.title[language].length > 0) ||
            section.title.length > 0
        "
        class="flex gap-3 mb-3 items-center px-5 md:px-0 nohighlight"
      >
        <div class="flex-1">
          <div v-if="language && typeOf(section.title) !== 'string'" class="relative font-bold text-2xl">
            {{ section.title[language] }}
          </div>
          <div v-else-if="section.title" class="relative font-bold text-2xl">
            {{ section.title }}
          </div>
          <div
            v-if="section.description && section.description[language] && section.description[language].length > 0"
            class="py-2 text-gray-800"
            v-html="section.description[language]"
          ></div>
        </div>
        <div
          v-if="section.required === true && editable && !exam"
          class="bg-gray-800 text-gray-100 rounded-full px-2 py-0.5 text-xs font-bold"
        >
          {{ mandatoryString }}
        </div>
        <div
          v-else-if="section.required === false && editable && !exam"
          class="bg-gray-100 text-gray-600 rounded-full px-2 py-0.5 text-xs font-bold"
        >
          {{ optionalString }}
        </div>
      </div>
      <div
        class="flex flex-col"
        :class="[
          {
            'bg-white border border-gray-100 md:border-none md:shadow-md rounded-lg p-5 ': section.boxed
          },
          inputGap
        ]"
      >
        <div v-for="(piece, p) in section.content" :id="'question-' + id + '-' + piece.key" :key="'piece_' + p">
          <div
            v-if="
              (language &&
                piece.label &&
                typeOf(piece.label) !== 'string' &&
                piece.label[language] &&
                piece.label[language].length > 0) ||
                (piece.label && piece.label.length > 0)
            "
            class="flex items-center gap-2 mb-1"
          >
            <label
              v-if="language && typeOf(piece.label) !== 'string'"
              class="font-semibold text-gray-500"
              :class="{ 'text-lg pb-2': !section.boxed, 'text-sm': section.boxed }"
              :for="piece[keyName]"
              >{{ piece.label[language] }}</label
            >
            <label
              v-else-if="piece.label"
              class="font-semibold text-gray-500"
              :class="{ 'text-lg pb-2': !section.boxed, 'text-sm': section.boxed }"
              :for="piece[keyName]"
              >{{ piece.label }}</label
            >

            <div
              v-if="piece.required === true && editable && !exam"
              class="bg-gray-800 text-gray-100 rounded-full px-2 py-0.5 text-xs font-bold nohighlight"
            >
              {{ mandatoryString }}
            </div>
            <div
              v-else-if="piece.required === false && editable && !exam"
              class="bg-gray-100 text-gray-600 rounded-full px-2 py-0.5 text-xs font-bold nohighlight"
            >
              {{ optionalString }}
            </div>
            <div class="flex-1"></div>
          </div>
          <div class="bg-white relative rounded-2xl flex gap-14" :class="{ 'p-5': !section.boxed }">
            <div v-if="exam && view == 'slides'" class="flex-1 flex flex-col">
              <div
                v-if="section.description && section.description[language] && section.description[language].length > 0"
                class="relative pl-11 py-2"
                @click="expanded = expanded == piece.key ? null : piece.key"
              >
                <div
                  class="w-8 bg-gray-100 rounded-t-full text-gray-500 text-center absolute text-sm left-0 top-0 bottom-0 leading-8 font-bold nohighlight pt-0.5"
                  :class="{
                    'text-gray-700': expanded == piece.key,
                    'text-gray-500': expanded != piece.key
                  }"
                >
                  {{ 'S' + (s + 1) }}
                </div>
                <div
                  class="overflow-hidden select-none cursor-pointer overflow-ellipsis relative after:content-[''] after:absolute after:bottom-0 after:left-0 after:right-0 after:bg-gradient-to-t after:to-transparent after:w-full after:h-10"
                  :class="{
                    'h-auto after:from-transparent text-gray-700': expanded == piece.key,
                    'h-20 after:from-white text-gray-500': expanded != piece.key
                  }"
                  v-html="
                    '<b>' +
                      (section.title && section.title[language] ? section.title[language] : section.title) +
                      '</b> ' +
                      section.description[language]
                  "
                ></div>
              </div>
              <div class="flex gap-3 pb-5">
                <div class="flex flex-shrink-0 -mt-0.5">
                  <div
                    class="bg-gray-300 rounded-b-full w-8 leading-8 text-center text-sm font-bold nohighlight z-[1]"
                    :class="{
                      'rounded-t-full h-8': !(
                        section.description &&
                        section.description[language] &&
                        section.description[language].length > 0
                      ),
                      'pt-0.5 pb-1 -mt-0.5':
                        section.description && section.description[language] && section.description[language].length > 0
                    }"
                  >
                    Q{{ p + 1 }}
                  </div>
                </div>
                <div class="flex-1 text-lg">
                  <p
                    v-if="piece.instructions && typeOf(piece.instructions[language]) == 'string'"
                    :class="{ 'font-semibold ': exam }"
                    v-html="piece.instructions[language]"
                  ></p>
                  <p v-else-if="piece.instructions" :class="{ 'font-semibold ': exam }" v-html="piece.instructions"></p>
                </div>
              </div>
              <div
                v-if="piece.file != null && piece.file.type === 'image'"
                class="overflow-hidden rounded-lg border border-gray-100 nohighlight flex-1 bg-black ml-10"
              >
                <img
                  class="w-full h-full cursor-pointer object-contain"
                  loading="lazy"
                  :src="getImageViewUrl(piece.file, 'max800')"
                  @click="openImageFullscreen(piece.file, piece)"
                />
              </div>
              <div
                v-else-if="piece.file != null && piece.file.type === 'video'"
                class="overflow-hidden rounded-lg border border-gray-100 nohighlight flex-1 bg-black"
              >
                <video-player
                  :ref="'video-' + piece[keyName]"
                  :src="getFileViewUrl(piece.file)"
                  :type="piece.file.mimetype"
                />
              </div>
            </div>
            <div
              :class="{
                'flex-1': view != 'slides',
                'w-1/2 flex-shrink-0': view == 'slides'
              }"
            >
              <div
                v-if="piece.file != null && piece.file.type === 'image' && view != 'slides'"
                class="overflow-hidden rounded-2xl mb-2 nohighlight"
              >
                <img
                  class="w-full cursor-pointer"
                  loading="lazy"
                  :src="getImageViewUrl(piece.file, 'max800')"
                  @click="openImageFullscreen(piece.file, piece)"
                />
              </div>
              <div
                v-else-if="piece.file != null && piece.file.type === 'video' && view != 'slides'"
                class="nohighlight"
              >
                <video-player
                  :ref="'video-' + piece[keyName]"
                  :src="getFileViewUrl(piece.file)"
                  :type="piece.file.mimetype"
                />
              </div>
              <div v-if="view != 'slides'" class="flex gap-3 mt-3 mb-1">
                <div class="flex flex-shrink-0 -mt-0.5">
                  <div
                    class="bg-gray-900 rounded-full w-8 h-8 text-base text-white leading-8 text-center font-bold nohighlight"
                  >
                    {{ p + 1 }}
                  </div>
                </div>
                <div class="flex-1">
                  <p
                    v-if="piece.instructions && typeOf(piece.instructions[language]) == 'string'"
                    class="pr-14 text-xl"
                    :class="{ 'font-semibold ': exam }"
                    v-html="piece.instructions[language]"
                  ></p>
                  <p
                    v-else-if="piece.instructions"
                    :class="{ 'font-semibold ': exam }"
                    class="pr-14 text-xl"
                    v-html="piece.instructions"
                  ></p>
                </div>
              </div>
              <div
                :class="{
                  'sticky top-5': view == 'slides'
                }"
              >
                <fw-label v-if="piece.type === 'multiple_choice' && exam" class="mb-3 nohighlight">
                  <span v-if="language === 'pt'">Escolha {{ piece.max }} das seguintes opções:</span>
                  <span v-else>Select {{ piece.max }} of the following options:</span>
                </fw-label>
                <TextInput
                  v-if="piece.type === 'text_input'"
                  :id="piece[keyName]"
                  :disabled="!editable"
                  :value="tmp_data[piece[keyName]] ? tmp_data[piece[keyName]] : ''"
                  :class="{ error: errors[piece[keyName]] && errors[piece[keyName]].length > 0 && editable }"
                  :placeholder="piece.placeholder"
                  @input="dataChanged(piece[keyName], piece.type, $event)"
                  @focus="focusInput(piece[keyName])"
                  @blur="focusInput(piece[keyName], null, 'blur')"
                >
                  <template v-if="piece.prefix" #prefix>{{ piece.prefix }}</template>
                  <template v-if="piece.suffix" #suffix>{{ piece.suffix }}</template>
                </TextInput>
                <TranslatedInput
                  v-if="piece.type === 'text_area'"
                  :maxlength="piece.maxChars"
                  :maxchars="piece.maxChars"
                  :value="tmp_data[piece[keyName]] ? tmp_data[piece[keyName]] : ''"
                  :multiline="true"
                  :autogrow="true"
                  :min-height="200"
                  :tablet="isDigitalPad"
                  :show-save-button="false"
                  :placeholder="'Escreva aqui...'"
                  :saving="saving && (dirty.has(piece.key) || failed.has(piece.key))"
                  @input="dataChanged(piece[keyName], piece.type, $event)"
                  @save="dataChanged(piece[keyName], piece.type, $event, true)"
                  @focus="focusInput(piece[keyName])"
                  @blur="focusInput(piece[keyName], null, 'blur')"
                ></TranslatedInput>
                <div v-else-if="piece.type === 'group'" class="grid gap-3 grid-cols-2 md:grid-cols-4 nohighlight">
                  <div
                    v-for="(subpiece, ss) in piece.content"
                    :key="'subpiece_' + ss"
                    :class="{
                      //'w-32': subpiece.type === 'postal_code',
                      //'flex-1': subpiece.type !== 'postal_code',
                      'col-span-1 md:col-span-3': subpiece[keyName] === 'locality',
                      error: errors[subpiece[keyName]] && errors[subpiece[keyName]].length > 0 && editable
                    }"
                  >
                    <label
                      v-if="subpiece.label"
                      class="font-semibold text-gray-500 mb-1 text-xs"
                      :for="subpiece[keyName]"
                      >{{ subpiece.label }}</label
                    >
                    <TextInput
                      v-if="subpiece.type === 'postal_code' || subpiece.type === 'text_input'"
                      :value="tmp_data[subpiece[keyName]] ? tmp_data[subpiece[keyName]] : ''"
                      :placeholder="subpiece.placeholder"
                      :disabled="!editable"
                      @input="dataChanged(subpiece[keyName], subpiece.type, $event)"
                      @focus="focusInput(subpiece[keyName])"
                      @blur="focusInput(subpiece[keyName], null, 'blur')"
                    ></TextInput>
                  </div>
                </div>
                <CountriesSelect
                  v-else-if="piece.type === 'country_input'"
                  :class="{ error: errors[piece[keyName]] && errors[piece[keyName]].length > 0 && editable }"
                  :disabled="!editable"
                  :input="tmp_data[piece[keyName]] ? tmp_data[piece[keyName]] : ''"
                  @update="dataChanged(piece[keyName], piece.type, $event)"
                  @focus="focusInput(piece[keyName])"
                  @blur="focusInput(piece[keyName], null, 'blur')"
                />
                <div
                  v-else-if="piece.type === 'upload_file'"
                  class="nohighlight"
                  :class="{ error: errors[piece[keyName]] && errors[piece[keyName]].length > 0 && editable }"
                >
                  <p v-if="tmp_data[piece[keyName]].length === 0 && editable === false" class="instructions">
                    {{ $t('noSectionDocuments') }}
                  </p>
                  <div class="files has-margin-top">
                    <RecordFileEntry
                      v-for="file in tmp_data[piece[keyName]] ? tmp_data[piece[keyName]] : []"
                      :key="file.key"
                      :can-edit="editable"
                      :allow-classified="false"
                      :file="file"
                      :can-download="typeOf(file.url_format) == 'string' || typeOf(file.url == 'string')"
                      @remove="removeFile(piece[keyName], $event)"
                      @save="saveFile(piece[keyName], $event)"
                      @download="downloadFile"
                    />
                  </div>
                  <StorageUploader
                    v-if="
                      editable &&
                        (typeOf(piece.max) === 'undefined' ||
                          (piece.max && tmp_data[piece[keyName]].length < piece.max))
                    "
                    :label="'Carregar ficheiros'"
                    :is-docked="true"
                    :layout="'minimal'"
                    :reference-id="'uploader_' + piece[keyName]"
                    :clear-after="true"
                    :upload-path="piece.uploadPath"
                    input-id="upload_input"
                    bucket-code="files"
                    bucket-type="file"
                    :files.sync="filesToUpload"
                    :size="0"
                    :new-file-context="newFileContext"
                    :file-type="piece.fileType || 'file'"
                    :file-code="piece.fileCode"
                    class="cursor-pointer"
                    @focus="focusInput(piece[keyName])"
                    @blur="focusInput(piece[keyName], null, 'blur')"
                    @upload="uploadedToStorage(piece, piece[keyName], $event)"
                  />
                  <!-- @upload="addFiles(piece[keyName], $event)" -->
                </div>
                <div
                  v-else-if="piece.type === 'files'"
                  :class="{ error: errors[piece[keyName]] && errors[piece[keyName]].length > 0 && editable }"
                  class="nohighlight"
                >
                  <p v-if="tmp_data[piece[keyName]].length === 0 && editable === false" class="instructions">
                    {{ $t('noSectionDocuments') }}
                  </p>
                  <div class="files has-margin-top">
                    <RecordFileEntry
                      v-for="file in tmp_data[piece[keyName]] ? tmp_data[piece[keyName]] : []"
                      :key="file.key"
                      :can-edit="editable"
                      :allow-classified="false"
                      :file="file"
                      @remove="removeFile(piece[keyName], $event)"
                      @download="downloadFile"
                    />
                  </div>
                  <Uploader
                    v-if="
                      editable &&
                        (typeOf(piece.max) === 'undefined' ||
                          (piece.max && tmp_data[piece[keyName]].length < piece.max))
                    "
                    :label="'Carregar ficheiros'"
                    :is-docked="true"
                    :layout="'minimal'"
                    :reference-id="'uploader_' + piece[keyName]"
                    :allowed="piece.allowed ? piece.allowed : 'pdf'"
                    :clear-after="true"
                    :upload-path="piece.uploadPath"
                    input-id="upload_input"
                    :files.sync="filesToUpload"
                    :size="0"
                    :new-file-context="newFileContext"
                    :file-type="piece.fileType || 'file'"
                    :file-code="piece.fileCode"
                    class="cursor-pointer"
                    @focus="focusInput(piece[keyName])"
                    @blur="focusInput(piece[keyName], null, 'blur')"
                    @upload="uploaded(piece, piece[keyName], $event)"
                  />
                  <!-- @upload="addFiles(piece[keyName], $event)" -->
                </div>
                <div
                  v-else-if="piece.type === 'identity_doc'"
                  class="grid gap-3 grid-cols-2 md:grid-cols-4 nohighlight"
                  :class="{ error: errors[piece[keyName]] && errors[piece[keyName]].length > 0 && editable }"
                >
                  <div>
                    <label class="font-semibold text-gray-500 mb-1 text-xs" :for="'identity_type_' + piece[keyName]">{{
                      $t('id.type.label')
                    }}</label>
                    <b-select
                      :value="tmp_data[piece[keyName]]['type']"
                      class="fw-select w-full"
                      :disabled="!editable"
                      :placeholder="$t('id.type.placeholder')"
                      :name="'identity_type_' + piece[keyName]"
                      @input="dataChanged(piece[keyName], 'id_type', $event)"
                      @focus="focusInput(piece[keyName], 'id_type')"
                      @blur="focusInput(piece[keyName], 'id_type', 'blur')"
                    >
                      <option value="cc">{{ $t('id.type.cc') }}</option>
                      <option value="passport">{{ $t('id.type.passport') }}</option>
                    </b-select>
                  </div>
                  <div>
                    <label
                      class="font-semibold text-gray-500 mb-1 text-sm text-xs"
                      :for="'identity_code_' + piece[keyName]"
                      >{{ $t('id.number') }}</label
                    >
                    <b-input
                      :value="tmp_data[piece[keyName]]['number']"
                      :disabled="!editable"
                      :placeholder="'Número do documento'"
                      type="text"
                      :name="'identity_code_' + piece[keyName]"
                      @input="dataChanged(piece[keyName], 'id_number', $event)"
                      @focus="focusInput(piece[keyName], 'id_number')"
                      @blur="focusInput(piece[keyName], 'id_number', 'blur')"
                    />
                  </div>

                  <div v-if="piece.withEmittedDate && piece.withEmittedDate === true">
                    <label class="font-semibold text-gray-500 mb-1 text-xs">{{ $t('id.emittedDate.label') }}</label>
                    <b-datepicker
                      :value="tmp_data[piece[keyName]]['emittedDate']"
                      :disabled="!editable"
                      :placeholder="$t('id.emittedDate.label')"
                      icon="calendar-plus"
                      @input="dataChanged(piece[keyName], 'id_emitted', $event)"
                      @focus="focusInput(piece[keyName], 'id_emitted')"
                      @blur="focusInput(piece[keyName], 'id_emitted', 'blur')"
                    >
                    </b-datepicker>
                  </div>
                  <div>
                    <label class="font-semibold text-gray-500 mb-1 text-xs">{{ $t('id.validDate.label') }}</label>
                    <b-datepicker
                      :value="tmp_data[piece[keyName]]['expireDate']"
                      position="is-bottom-left"
                      :disabled="!editable"
                      :placeholder="$t('id.validDate.label')"
                      icon="calendar-plus"
                      @input="dataChanged(piece[keyName], 'id_expired', $event)"
                      @focus="focusInput(piece[keyName], 'id_expired')"
                      @blur="focusInput(piece[keyName], 'id_expired', 'blur')"
                    >
                    </b-datepicker>
                  </div>
                </div>
                <div
                  v-else-if="piece.type === 'phone_number'"
                  class="flex gap-3 nohighlight"
                  :class="{ error: errors[piece[keyName]] && errors[piece[keyName]].length > 0 && editable }"
                >
                  <div>
                    <label class="font-semibold text-gray-500 text-xs">{{ $t('phoneCountry') }}</label>
                    <PhoneCountriesSelect
                      :disabled="!editable"
                      :input="tmp_data[piece[keyName]]['country']"
                      @update="dataChanged(piece[keyName], 'phone_number_code', $event)"
                      @focus="focusInput(piece[keyName], 'phone_number_country_code')"
                      @blur="focusInput(piece[keyName], 'phone_number_country_code', 'blur')"
                    />
                  </div>
                  <div class="flex-1">
                    <label class="font-semibold text-gray-500 text-xs">{{ $t('phone') }}</label>
                    <b-input
                      v-model="tmp_data[piece[keyName]]['number']"
                      :placeholder="$t('phone')"
                      :disabled="!editable"
                      type="text"
                      :name="tmp_data[piece[keyName]]"
                      @input="dataChanged(piece[keyName], 'phone_number_main', $event)"
                      @focus="focusInput(piece[keyName], 'phone_number')"
                      @blur="focusInput(piece[keyName], 'phone_number', 'blur')"
                    />
                  </div>
                </div>
                <b-switch
                  v-else-if="piece.type === 'switch'"
                  v-model="tmp_data[piece[keyName]]"
                  :class="{ error: errors[piece[keyName]] && errors[piece[keyName]].length > 0 && editable }"
                  class="nohighlight"
                  :disabled="!editable"
                  @input="dataChanged(piece[keyName], piece.type, $event)"
                  @focus="focusInput(piece[keyName])"
                  @blur="focusInput(piece[keyName], null, 'blur')"
                >
                  {{ piece.text }}
                </b-switch>
                <b-select
                  v-else-if="piece.type === 'select'"
                  v-model="tmp_data[piece[keyName]]"
                  :class="{ error: errors[piece[keyName]] && errors[piece[keyName]].length > 0 && editable }"
                  name="type"
                  :disabled="!editable"
                  expanded
                  class="fw-select nohighlight"
                  @input="dataChanged(piece[keyName], piece.type, $event)"
                  @focus="focusInput(piece[keyName])"
                  @blur="focusInput(piece[keyName], null, 'blur')"
                >
                  <option v-for="(type_key, k) in piece.options" :key="piece[keyName] + '_' + k" :value="type_key.key">
                    {{ type_key.text }}
                  </option>
                </b-select>
                <div
                  v-else-if="piece.type === 'formatted_text'"
                  class="html-editor nohighlight"
                  :class="{ error: errors[piece[keyName]] && errors[piece[keyName]].length > 0 && editable }"
                >
                  <editor-menu-bar v-slot="{ commands, isActive, focused }" :editor="editors[piece[keyName]]">
                    <div class="menubar" :class="{ 'is-focused': focused }">
                      <button
                        class="button is-text is-small"
                        :class="{ 'is-active': isActive.bold() }"
                        @click="commands.bold"
                      >
                        <icon name="bold" />
                      </button>

                      <button
                        class="button is-text is-small"
                        :class="{ 'is-active': isActive.italic() }"
                        @click="commands.italic"
                      >
                        <icon name="italic" />
                      </button>

                      <button
                        class="button is-text is-small"
                        :class="{ 'is-active': isActive.underline() }"
                        @click="commands.underline"
                      >
                        <icon name="underline" />
                      </button>

                      <button
                        class="button is-text is-small"
                        :class="{ 'is-active': isActive.paragraph() }"
                        @click="commands.paragraph"
                      >
                        <icon name="paragraph" />
                      </button>

                      <button class="button is-text is-small" @click="commands.undo">
                        <icon name="undo" />
                      </button>

                      <button class="button is-text is-small" @click="commands.redo">
                        <icon name="redo" />
                      </button>
                    </div>
                  </editor-menu-bar>
                  <editor-content :editor="editors[piece[keyName]]" @focus="focusInput(piece[keyName])" />
                </div>
                <MultipleChoice
                  v-else-if="piece.type === 'multiple_choice'"
                  :ref="'multiple_choice_' + piece[keyName]"
                  :options="piece.options"
                  class="mt-2.5 nohighlight"
                  :max="piece.max"
                  :random="randomOptions"
                  :language="language"
                  :saving="saving"
                  :mode="isDigitalPad ? 'tablet' : 'normal'"
                  @selected="dataChanged(piece[keyName], piece.type, $event, true)"
                ></MultipleChoice>
                <div v-else-if="piece.type === 'image'" class="overflow-hidden rounded-2xl mt-2.5 nohighlight">
                  <img
                    class="w-full cursor-pointer"
                    :src="getImageViewUrl(piece.file, 'max800')"
                    @click="openImageFullscreen(piece.file)"
                  />
                </div>
                <div
                  v-else-if="piece.type === 'image_direct'"
                  class="overflow-hidden rounded-2xl bg-gray-100 mt-2.5 nohighlight"
                  style="min-height: 90px"
                >
                  <img :src="piece.directUrl" class="w-full" />
                </div>
                <div v-else-if="piece.type === 'video'" class="nohighlight">
                  <video-player
                    :ref="'video-' + piece[keyName]"
                    :src="getFileViewUrl(piece.file)"
                    :type="piece.file.mimetype"
                  />
                </div>
                <TranslatedInput
                  v-else-if="
                    piece.type === 'textarea_input' ||
                      piece.type === 'multilanguage_text_input' ||
                      piece.type === 'multilanguage_textarea_input'
                  "
                  class="nohighlight"
                  :value="tmp_data[piece[keyName]]"
                  :multiline="piece.type === 'textarea_input' || piece.type === 'multilanguage_textarea_input'"
                  :autogrow="true"
                  :min-height="200"
                >
                </TranslatedInput>
                <div v-if="piece.type === 'group' && editable" class="nohighlight">
                  <div v-for="(subpiece, ss) in piece.content" :key="'subpiece_error_' + ss">
                    <div
                      v-if="errors[subpiece[keyName]] && errors[subpiece[keyName]].length > 0"
                      class="text-sm mt-1.5"
                      style="color: #f15f1f"
                    >
                      {{ errors[subpiece[keyName]] }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="errors[piece[keyName]] && errors[piece[keyName]].length > 0 && editable"
                  class="text-sm mt-1.5 nohighlight"
                  style="color: #f15f1fff"
                >
                  {{ errors[piece[keyName]] }}
                </div>
                <div v-else-if="piece.message" class="text-xs mt-1.5 nohighlight">{{ piece.message }}</div>
                <div v-if="debug" class="bg-yellow-100 p-3 rounded-md mt-2 text-xs">
                  <b>Question Key:</b> {{ piece.key }}<br />
                  <b>Question Type:</b> {{ piece.type }}<br />
                  <b>MC options:</b> {{ piece.options }}<br />
                  <b>Local value:</b> {{ data[piece.key] }}<br />
                  <b>Saving question:</b> {{ saved.has(piece.key) }}<br />
                  <b>Question is dirty:</b> {{ dirty.has(piece.key) }}<br />
                  <b>Question has failed:</b> {{ failed.has(piece.key) }}<br />
                  <b>Remote data (after saving):</b> <span :ref="'debug_remote' + piece[keyName]">--</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="showImageFullScreen" class="z-40 fixed top-16 left-0 right-0 bottom-0 flex bg-black nohighlight">
      <div
        class="cursor-pointer w-12 h-12 fixed z-50 top-20 right-5 rounded-full flex justify-center items-center text-white bg-black bg-opacity-30"
        @click="closeFullScreen"
      >
        <fw-icon-close class="h-8 w-8" />
      </div>
      <div class="flex-1">
        <v-zoomer v-if="activeFullscreenImage" class="h-full w-full">
          <img :src="activeFullscreenImage" class="h-full w-full object-contain" />
        </v-zoomer>
      </div>
      <div v-if="false && fullScreenQuestion != null" class="h-full w-1/5 bg-white shrink-0">Pergunta</div>
    </div>

    <fw-panel-info v-if="debug" label="Data (raw)">
      <json-viewer :value="{ form, tmp_data }"></json-viewer>
    </fw-panel-info>
  </form>
</template>

<script>
import VueZoomer from 'vue-zoomer'

import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import { Bold, Italic, Link, Underline, History, Placeholder } from 'tiptap-extensions'
import TextInput from './TextInput'
import CountriesSelect from './CountriesSelect'
import RecordFileEntry from './RecordFileEntry'
import Uploader from '@/fw-modules/fw-core-vue/storage/components/PathUploader.vue'
import StorageUploader from '@/fw-modules/fw-core-vue/storage/components/Uploader.vue'
import PhoneCountriesSelect from './PhoneCountriesSelect'
//import ServiceBuckets from '../../../buckets/services/ServiceBuckets'
import MultipleChoice from './MultipleChoice'
import ServiceStorage from '../../../storage/services/ServiceStorage'
import VideoPlayer from '@/fw-modules/fw-core-vue/ui/components/video/VideoPlayer'
import TranslatedInput from './TranslatedInput'
export default {
  name: 'Form',
  components: {
    MultipleChoice,
    TextInput,
    CountriesSelect,
    RecordFileEntry,
    Uploader,
    PhoneCountriesSelect,
    EditorContent,
    EditorMenuBar,
    VideoPlayer,
    TranslatedInput,
    StorageUploader,
    VZoomer: VueZoomer.Zoomer
  },
  props: {
    fontSizeRatio: {
      type: Number,
      default: 1
    },
    meetingsSubjectOptions: {
      type: Array,
      default: function() {
        return []
      }
    },
    meetingsVirtualRoom: {
      type: Object,
      default: function() {
        return {}
      }
    },
    data: {
      type: Object,
      default: function() {
        return {}
      }
    },
    exam: {
      type: Boolean,
      default: false
    },
    keyName: {
      type: String,
      default: 'id'
    },
    form: {
      type: Array,
      default: function() {
        return []
      }
    },
    editable: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Object,
      default: function() {
        return {}
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    bucketId: {
      type: String,
      default: null
    },
    folderId: {
      type: String,
      default: null
    },
    disableContextMenu: {
      type: Boolean,
      default: false
    },
    language: {
      type: String,
      default: 'pt'
    },
    languagesOptions: {
      type: Array,
      default: function() {
        return ['pt', 'en']
      }
    },
    id: {
      type: String,
      default: 'fw_form'
    },
    inputGap: {
      //tailwind gap property
      type: String,
      default: 'gap-12'
    },
    sectionGap: {
      //tailwind gap property
      type: String,
      default: 'gap-7'
    },
    realtimeDatachange: {
      type: Boolean,
      default: false
    },
    successRequest: {
      type: Boolean,
      default: false
    },
    failedRequest: {
      type: Boolean,
      default: false
    },
    showSavingIndicators: {
      type: Boolean,
      default: false
    },
    dirtyInputs: {
      type: Set,
      default: function() {
        return null
      }
    },
    // the page prefix is used so the dirtyInputs keys match the ones in the exam
    pagePrefix: {
      type: String,
      default: ''
    },
    saving: {
      type: Boolean,
      default: false
    },
    textAreaSaveButton: {
      type: Boolean,
      default: false
    },
    randomOptions: {
      type: Boolean,
      default: false
    },
    view: {
      type: String,
      default: 'form'
    }
  },

  data() {
    return {
      expanded: null,
      debug: localStorage.getItem('fw-exam-debug') == 'true' && process.env.NODE_ENV === 'development',
      activeFullscreenImage: null,
      fullScreenQuestion: null,
      currentEditing: null,
      selectedModalValues: [], //preselected values
      modalTitle: null,
      modalMaxLen: 0,
      modalInstructions: null,
      multiselectModal: false, //true if max > 1
      activeModal: null, //tipo de input?
      activeModalID: null, //id do input
      filesToUpload: [],
      tmp_data: {},
      editors: {},
      currentfocus_id: null,
      currentfocus_subtype: null,
      restore_context_menu: null,
      ctrlDown: false,
      listeners: [],
      startInput: '', //to count words per second we store here the initial input value
      debouceTimer: null,
      dirty: new Set(), //array of dirty fields to be saved
      saved: new Set(), //array of recently saved fields
      failed: new Set(), //array of recently failed fields
      savedTimeout: null
    }
  },

  computed: {
    isDigitalPad() {
      return process.env.VUE_APP_KEY == 'ucdigitalpad'
    },
    showImageFullScreen() {
      return this.activeFullscreenImage !== null
    },
    computedFontSize() {
      return this.fontSizeRatio * 100 + '%'
    },
    hasLogListener() {
      return this.$listeners && this.$listeners.logs
    },
    newFileContext() {
      return { bucket: this.bucketId, folder: this.folderId }
    },
    mandatoryString: function() {
      return 'Obrigatório'
    },
    optionalString: function() {
      return 'Opcional'
    },
    user() {
      return this.$store.getters.getUser
    }
  },

  created() {
    if (this.hasLogListener) {
      const ctrlKey = 17,
        cmdKey = 91,
        vKey = 86,
        cKey = 67

      this.registerListener('document', 'keydown', key => {
        let code = key.keyCode
        if (code === ctrlKey || code === cmdKey) {
          this.ctrlDown = true
        } else if (this.ctrlDown && code === cKey) {
          //copy event
          this.sendLogs({
            time: new Date(),
            action: 'copy',
            inputID: this.currentfocus_id ? this.currentfocus_id : 'window',
            subinput: this.currentfocus_subtype
          })
        } else if (this.ctrlDown && code === vKey) {
          //paste event
          this.sendLogs({
            time: new Date(),
            action: 'paste',
            inputID: this.currentfocus_id ? this.currentfocus_id : 'window',
            subinput: this.currentfocus_subtype
          })
        }
      })

      this.registerListener('document', 'keyup', key => {
        let code = key.keyCode
        if (code === ctrlKey || code === cmdKey) {
          this.ctrlDown = false
        }
      })

      this.registerListener('window', 'focus', () => {
        this.sendLogs({
          time: new Date(),
          action: 'focus',
          inputID: 'window'
        })
      })

      this.registerListener('window', 'blur', () => {
        this.sendLogs({
          time: new Date(),
          action: 'blur',
          inputID: 'window'
        })
      })

      this.registerListener('document', 'fullscreenchange', () => {
        if (document.fullscreenElement) {
          this.sendLogs({
            time: new Date(),
            action: 'enter_full_screen',
            inputID: document.fullscreenElement.id
          })
          //console.log(`Element: ${document.fullscreenElement.id} entered full-screen mode.`);
        } else {
          this.sendLogs({
            time: new Date(),
            action: 'leaving_full_screen',
            inputID: 'window'
          })
        }
      })
    }

    if (this.disableContextMenu) {
      window.oncontextmenu = function() {
        //avoid right click / context menu
        return false
      }
    }

    //TODO: integrate real data from prop here
    let tmp_data = {}
    for (let i = 0; i < this.form.length; i++) {
      let section = this.form[i]
      for (let j = 0; j < section.content.length; j++) {
        let input = section.content[j]

        if (input.type === 'group') {
          for (let k = 0; k < input.content.length; k++) {
            let subinput = input.content[k]
            if (this.data[subinput[this.keyName]]) {
              tmp_data[subinput[this.keyName]] = this.data[subinput[this.keyName]]
            } else if (subinput.type === 'files') {
              tmp_data[subinput[this.keyName]] = this.data[subinput[this.keyName]]
                ? this.data[subinput[this.keyName]]
                : []
            } else {
              tmp_data[subinput[this.keyName]] = this.data[subinput[this.keyName]]
                ? this.data[subinput[this.keyName]]
                : ''
            }
          }
        } else {
          if (input[this.keyName]) {
            if (this.data[input[this.keyName]]) {
              tmp_data[input[this.keyName]] = this.data[input[this.keyName]]
            } else if (
              input.type === 'files' ||
              input.type === 'upload_file' ||
              input.type === 'person_input' ||
              input.type === 'meeting_input' ||
              input.type === 'multiple_choice'
            ) {
              tmp_data[input[this.keyName]] = []
            } else if (input.type === 'image_direct' || input.type === 'text_content') {
              //IGNORE!
            } else if (input.type === 'identity_doc') {
              tmp_data[input[this.keyName]] = {
                type: '',
                number: '',
                emittedDate: null,
                expireDate: null
              }
            } else if (input.type === 'country_input') {
              tmp_data[input[this.keyName]] = {
                key: 'PT',
                title: 'Portugal'
              } //set Portugal as default
            } else if (input.type === 'phone_number') {
              tmp_data[input[this.keyName]] = {
                code: '+351',
                country: {
                  code: '+351',
                  key: 'PT',
                  title: 'Portugal'
                },
                number: ''
              }
            } else if (input.type === 'multilanguage_text_input' || input.type === 'multilanguage_textarea_input') {
              tmp_data[input[this.keyName]] = {}
              for (let k = 0; k < this.languagesOptions.length; k++) {
                tmp_data[input[this.keyName]][this.languagesOptions[k]] = this.data[input[this.keyName]]
                  ? this.data[input[this.keyName]][this.languagesOptions[k]]
                    ? this.data[input[this.keyName]][this.languagesOptions[k]]
                    : ''
                  : ''
              }
            } else if (input.type === 'switch') {
              tmp_data[input[this.keyName]] = this.data[input[this.keyName]] ? true : false
            } else {
              tmp_data[input[this.keyName]] = ''
            }
            if (input.type === 'formatted_text') {
              this.editors[input[this.keyName]] = new Editor({
                editable: this.editable,
                content: tmp_data[input[this.keyName]],
                extensions: [
                  new Link(),
                  new Bold(),
                  new Italic(),
                  new Underline(),
                  new History(),
                  new Placeholder({
                    emptyNodeClass: 'is-empty',
                    emptyNodeText: this.$t('writeText'),
                    showOnlyWhenEditable: true
                  })
                ],
                onUpdate: ({ getHTML }) => {
                  //this.tmp_data[input[this.keyName]] = getHTML()
                  this.dataChanged(input[this.keyName], 'formatted_text', getHTML())
                },
                onFocus({ getHTML }) {
                  if (this.hasLogListener) {
                    this.startInput = getHTML()
                    let data = {
                      time: new Date(),
                      action: 'focus',
                      inputID: input[this.keyName]
                    }
                    this.sendLogs(data)
                  }
                },
                onBlur() {
                  /*if (this.hasLogListener) {
                    let data = {
                      time: new Date(),
                      action: 'blur',
                      inputID: input[this.keyName],
                    }
                    this.sendLogs(data)
                  }*/
                }
              })
            }
          } else {
            throw Error('input with no id!')
          }
        }
      }
    }
    this.tmp_data = tmp_data
  },

  beforeDestroy() {
    //Destroy all editors
    for (const [key] of Object.entries(this.editors)) {
      this.editors[key].destroy()
    }

    //Remove events listeners
    if (this.listeners.length > 0) {
      for (let i = 0; i < this.listeners.length; i++) {
        let listener = this.listeners[i]
        if (listener.element === 'window') {
          window.removeEventListener(listener.event, listener.action)
        } else if (listener.element === 'document') {
          document.removeEventListener(listener.event, listener.action)
        } else {
          throw 'Not implemented listener element'
        }
      }
    }

    //RESTORE right click
    if (this.disableContextMenu) {
      window.oncontextmenu = null
    }

    if (this.debouceTimer !== null) {
      clearTimeout(this.debouceTimer)
      this.emitDataChanged() //there is data that was not processed!
    }
  },

  methods: {
    closeFullScreen() {
      console.log('closing')
      this.activeFullscreenImage = null
      this.fullScreenQuestion = null
    },
    openImageFullscreen(imageFile, question) {
      let imgUrl = this.getImageViewUrl(imageFile, 'max2k')
      console.log('open', imgUrl)
      this.activeFullscreenImage = imgUrl
      if (question.type == 'multiple_choice') {
        this.fullScreenQuestion = question
      }
    },
    typeOf(element) {
      return typeof element
    },
    choosePeople(id, title, instructions, max = 1) {
      this.activeModal = 'person_input'
      this.activeModalID = id
      this.selectedModalValues = this.tmp_data[id] //preselected values
      this.modalTitle = title
      this.modalInstructions = instructions
      this.multiselectModal = max > 1
      this.modalMaxLen = max
    },
    removePerson(id, pos) {
      let personKey = this.tmp_data[id][pos].key
      this.tmp_data[id].splice(pos, 1)

      this.emitDataChanged()
      if (this.hasLogListener) {
        this.sendLogs({
          time: new Date(),
          action: 'removePerson',
          inputID: id,
          elements: [personKey]
        })
      }
    },
    newMeeting(id) {
      this.currentEditing = null
      this.activeModal = 'meeting_input'
      this.activeModalID = id
    },
    editMeeting(id, pos) {
      this.currentEditing = this.tmp_data[id][pos]
      this.activeModal = 'meeting_input'
      this.activeModalID = id
    },
    deleteMeeting(id, pos) {
      let meetingKey = this.tmp_data[id][pos].key
      this.tmp_data[id].splice(pos, 1)

      this.emitDataChanged()
      if (this.hasLogListener) {
        this.sendLogs({
          time: new Date(),
          action: 'removeMeeting',
          inputID: id,
          elements: [meetingKey]
        })
      }
    },
    saveModal(value) {
      let action = 'edited_'
      if (this.currentEditing) {
        this.tmp_data[this.activeModalID][this.currentEditing] = value
      } else {
        action = 'added_'
        this.tmp_data[this.activeModalID].push(value)
      }
      this.emitDataChanged()
      if (this.hasLogListener) {
        this.sendLogs({
          time: new Date(),
          action: action + this.activeModal,
          inputID: this.activeModalID,
          elements: []
        })
      }
    },
    selectedModal(value) {
      let newvalue = this.tmp_data[this.activeModalID]
      let excessLen = value.length + newvalue.length - this.modalMaxLen
      let start = newvalue.length - excessLen
      if (start < 0) {
        excessLen -= newvalue.length
        newvalue = []
        value.splice(value.length - excessLen, excessLen)
      } else {
        newvalue.splice(start, excessLen)
      }
      this.dataChanged(this.activeModalID, this.activeModal, newvalue.concat(value))
    },
    cancelModal() {
      this.activeModal = null
    },
    playerReady(player) {
      //Deactivate download and right click on the player
      player.el_.childNodes[0].setAttributeNS(null, 'controlsList', 'nodownload')
      player.el_.childNodes[0].setAttribute('oncontextmenu', 'return false;')
    },
    getFileViewUrl(file) {
      let publicToken = file.token || this.user.token
      return ServiceStorage.getFileUrl(file, publicToken)
    },
    getImageViewUrl(file, size) {
      let publicToken = file.token || this.user.token
      return ServiceStorage.getImageViewUrl(file, size, publicToken)
    },
    registerListener(element, event, action) {
      this.listeners.push({
        element: element,
        event: event,
        action: action
      })
      if (element === 'window') {
        window.addEventListener(event, action)
      } else if (element === 'document') {
        document.addEventListener(event, action)
      } else {
        throw 'Not implemented listener element'
      }
    },
    //triggered by uploader
    /* async addFiles(id, files) {
      let fileKeys = []
      for (const file of files) {
        if (file.response.data) {
          const data = { files: [file.response.data.file.key] }
          if (file.context.folder) data.folder_key = file.context.folder

          const response = await ServiceBuckets.addItemsToBucket(file.context.bucket, data)
          console.log('response', response)
          if (file.context.bucket == this.bucketId && file.context.folder == this.folderId && response.new_items) {
            //this.addItems(response.new_items)
            //this.showFullDragZoneUploader = false
            //tmp_data[piece[this.keyName]]
            fileKeys = fileKeys.concat(response.new_items.map(file => file.key))
            let newdata = this.tmp_data[id].concat(response.new_items)
            this.tmp_data[id] = newdata
          }
        }
      }
      this.emitDataChanged()
      if (this.hasLogListener) {
        this.sendLogs({
          time: new Date(),
          action: 'upload',
          inputID: id,
          elements: fileKeys,
        })
      }
    }, */
    async uploadedToStorage(piece, id, files) {
      console.log(piece, id, files)
      let newfiles = []
      for (const file of files) {
        if (file.response.data) {
          //const data = { files: [file.response.data.file.key] }
          //if (file.context.folder) data.folder_key = file.context.folder

          //const response = await ServiceBuckets.addItemsToBucket(file.context.bucket, data)
          //console.log('response', response)
          //if (file.context.bucket == this.bucketId && file.context.folder == this.folderId && response.new_items) {
          //this.addItems(response.new_items)
          //this.showFullDragZoneUploader = false
          //tmp_data[piece[this.keyName]]
          if (file.response.status === 'success') {
            //fileKeys = fileKeys.concat(response.new_items.map(file => file.key))

            /*

            file['title'] = file.filename
            newfiles.push(file)
            */
            let fileData = file.response.data.file
            fileData['title'] = fileData.filename
            newfiles.push(fileData)
            //let newdata = this.tmp_data[id].concat(file.response.data.file)
          }

          //}
        }
      }

      if (piece.afterUpload) {
        await piece.afterUpload(newfiles.map(file => file.key))
      }

      this.tmp_data[id] = this.tmp_data[id].concat(newfiles)
      this.emitDataChanged()
      this.emitAtomicDataChanged(id, 'upload', this.tmp_data[id])
      if (this.hasLogListener) {
        this.sendLogs({
          time: new Date(),
          action: 'upload',
          inputID: id,
          elements: newfiles.map(file => file.key)
        })
      }
    },
    async uploaded(piece, id, files) {
      let newfiles = []
      for (const file of files) {
        if (file.response.data) {
          //const data = { files: [file.response.data.file.key] }
          //if (file.context.folder) data.folder_key = file.context.folder

          //const response = await ServiceBuckets.addItemsToBucket(file.context.bucket, data)
          //console.log('response', response)
          //if (file.context.bucket == this.bucketId && file.context.folder == this.folderId && response.new_items) {
          //this.addItems(response.new_items)
          //this.showFullDragZoneUploader = false
          //tmp_data[piece[this.keyName]]
          if (file.response.status === 'success') {
            //fileKeys = fileKeys.concat(response.new_items.map(file => file.key))
            newfiles.push(file.response.data.file)
            //let newdata = this.tmp_data[id].concat(file.response.data.file)
          }

          //}
        }
      }

      if (piece.afterUpload) {
        await piece.afterUpload(newfiles.map(file => file.key))
      }

      this.tmp_data[id] = this.tmp_data[id].concat(newfiles)
      this.emitDataChanged()
      if (this.hasLogListener) {
        this.sendLogs({
          time: new Date(),
          action: 'upload',
          inputID: id,
          elements: newfiles.map(file => file.key)
        })
      }
    },
    focusInput(inputID, subinput, action = 'focus') {
      if (this.hasLogListener) {
        if (action === 'focus') {
          this.currentfocus_id = inputID
          this.currentfocus_subtype = subinput
          this.startInput = this.tmp_data[inputID]

          let data = {
            time: new Date(),
            action: action,
            inputID: inputID
          }
          if (subinput) {
            data['subinput'] = subinput
          }

          this.sendLogs(data)
        } /* else {
          this.currentfocus_id = null
          this.currentfocus_subtype = null
          //this.startInput = ''
        }*/
      }
    },
    sendLogs(data) {
      if (this.hasLogListener) {
        this.$emit('logs', data)
      }
    },
    sendForm() {
      this.$emit('send', this.tmp_data)
    },
    async downloadFile(file) {
      this.$emit('download', file)
      /*let url
      if (file.url) {
        const response = await ServiceStorage.getFiles([file.key])
        url = file.url
        url = window.URL.createObjectURL(new Blob([response]))
      } else if (file.url_format) {
        url = this.getFileViewUrl(file)
      } else {
        //const response = await this.api.getFile(file.key, file.filename)
        //url = window.URL.createObjectURL(new Blob([response]))
        const response = await ServiceStorage.getFiles([file.key])
        console.log('DOWNLOAD FILE', response)
        url = this.getFileViewUrl(response)
      }

      const link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      link.setAttribute('download', file.filename)
      document.body.appendChild(link)
      link.click()*/
    },
    saveFile(id, file) {
      console.log('save file', id, file)
      if (this.hasLogListener) {
        this.sendLogs({
          time: new Date(),
          action: 'removeFile',
          inputID: id,
          elements: [file.key]
        })
      }
      if (this.showSavingIndicators) {
        this.dirty.add(id)
      }
      this.emitAtomicDataChanged(id, 'upload', this.tmp_data[id])
      this.emitDataChanged()
    },

    removeFile(id, file) {
      //console.log('file', file)
      this.$buefy.dialog.confirm({
        message: this.$t('deleteFileConfirm'),
        onConfirm: async () => {
          console.log('DELETE FILE1')
          for (let i = 0; i < this.tmp_data[id].length; i++) {
            let f = this.tmp_data[id][i]
            if (f.key === file.key) {
              console.log('DELETE FILE2')
              this.$emit('delete-file', file)
              this.tmp_data[id].splice(i, 1)
            }
          }

          //this.emitDataChanged()
          if (this.hasLogListener) {
            this.sendLogs({
              time: new Date(),
              action: 'removeFile',
              inputID: id,
              elements: [file.key]
            })
          }
          if (this.showSavingIndicators) {
            this.dirty.add(id)
          }
          this.emitDataChanged()
          this.emitAtomicDataChanged(id, 'upload', this.tmp_data[id])

          //const fileDeleted = await this.api.deleteFile(file.key)
          //if (fileDeleted) {
          //this.doSaveApplication(true)
          //  this.dataChanged()
          //}
        }
      })
    },
    updateFiles(id, others) {
      console.log('updateFiles', id, 'others', others)
    },
    emitDataChanged() {
      this.$emit('changed', this.tmp_data)
    },
    emitAtomicDataChanged(id, type, value) {
      this.$emit('atomic-changed', {
        id: id,
        type: type,
        value: value
      })
    },
    dataChanged(id, type, value, forceImediate = false) {
      console.log('dataChanged: id', id, 'type', type, 'value', value)
      let action = 'writing'
      if (type === 'files') {
        action = 'uploaded'
      } else if (type === 'multiple_choice') {
        action = 'selecting_option'
      } else {
        this.tmp_data[id] = value
      }

      if (this.showSavingIndicators) {
        this.dirty.add(id)
      }

      //if needed:
      //$emit("update:modelValue", $event.target.value)
      console.log('dataChanged: action', action)
      if (action === 'writing' && this.realtimeDatachange === false && !forceImediate) {
        //debounce
        if (this.debouceTimer !== null) {
          clearTimeout(this.debouceTimer)
        }
        this.emitAtomicDataChanged(id, type, value)
        this.debouceTimer = setTimeout(() => {
          this.emitDataChanged()
          this.sendLogs({
            time: new Date(),
            action: action,
            inputID: id,
            charPerSec: this.tmp_data[id].length - this.startInput.length,
            elements: [],
            value: value
          })
          this.startInput = this.tmp_data[id]
        }, 1000)
      }

      //emit immediately
      if (!forceImediate && (action !== 'writing' || this.realtimeDatachange === true)) {
        this.emitDataChanged()
        this.emitAtomicDataChanged(id, type, value)
        if (this.hasLogListener) {
          //Only imidiatly emit if it's not writing action
          this.sendLogs({
            time: new Date(),
            action: action,
            inputID: id,
            elements: []
          })
        }
      }
      //this.$store.commit('setDirtyData', true)
      if (forceImediate) {
        this.$emit('save-manual', {
          id: id,
          type: type,
          value: value
        })
      }
    },
    updateAnswers(data) {
      //New data from the server
      console.log('DATA inside the form', data)

      //loog through all the data keys and call function serverData for each
      for (const [key, value] of Object.entries(data)) {
        if (this.$refs['multiple_choice_' + key] && this.$refs['multiple_choice_' + key][0]) {
          this.$refs['multiple_choice_' + key][0].serverData(value)
        }
        if (this.$refs['debug_remote' + key] && this.$refs['debug_remote' + key][0]) {
          this.$refs['debug_remote' + key][0].innerHTML = value
        }
      }
    },
    getDirtyData() {
      //return all the dirty data (dirtyInputs)
      let data = {}
      for (const key of this.dirtyInputs) {
        //data[key] = this.tmp_data[key]
        //split key _
        let keys = key.split('_')
        if (keys[0] == this.id) {
          data[keys[1]] = this.tmp_data[keys[1]]
        }
      }
      return data
    }
  }
}
</script>

<style>
.my-remove-tip {
  box-sizing: border-box;
  position: absolute;
  border: 1px solid #fff;
  border-radius: 3px;
  height: 20px;
  width: 40px;
  color: #fff;
  background: #444;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  line-height: 18px;
  overflow: visible;
}

.my-remove-tip::after {
  content: '';
  position: absolute;
  left: 16px;
  bottom: -4px;
  border-color: #444 transparent transparent;
  border-width: 4px 4px 0;
  border-style: solid;
  height: 0;
  width: 0;
}

.video-js:hover .vjs-big-play-button {
  height: 2.5em !important;
  width: 2.5em !important;
  line-height: 2.5em !important;
  border-radius: 2em !important;
  @apply bg-primary border-primary shadow-lg scale-110;
}
.video-js {
  cursor: pointer;
  @apply overflow-hidden rounded-2xl;
}
.video-js .vjs-big-play-button {
  height: 2.5em !important;
  width: 2.5em !important;
  line-height: 2.5em !important;
  border-radius: 2em !important;
  @apply bg-primary border-primary scale-90;
}

.fw-form .file-uploads {
  @apply w-full rounded;
}
.fw-select .select {
  @apply rounded w-full;
}
.fw-select .select select {
  @apply w-full;
}
.fw-select .select:after {
  color: #999;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  transform: rotate(0deg);
  top: 60%;
}

.fw-select .select:not(.is-multiple):not(.is-loading)::after {
  color: #999;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  transform: rotate(0deg);
  top: 60%;
}

.fw-select:hover .select:not(.is-multiple):not(.is-loading)::after {
  color: #999;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  transform: rotate(0deg);
}

.fw-select:hover .select:after {
  color: #999;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  transform: rotate(0deg);
}
.fw-select:hover .select select {
  @apply border border-gray-200;
}
.fw-select .select select {
  @apply border border-gray-200;
}
.fw-select .select:not(.is-multiple) {
  height: 2.5rem;
}
.fw-select .select select {
  height: 2.5rem;
  padding-bottom: 0px;
  padding-top: 0px;
}

.fw-select .select select:focus {
  border-color: transparent;
  outline: solid 2px rgba(3, 164, 121, 0.7);
  outline-offset: 2px;
  box-shadow: none;
}

.multiselect {
  @apply rounded shadow border border-gray-200;
}

.multiselect .multiselect__select:before {
  color: #999;
  border-color: #999 transparent transparent;
  border-radius: 2px;
}

.fw-form button {
  outline: none;
}

.fw-form .textarea:focus,
.fw-form .input:focus,
.fw-form .taginput .taginput-container.is-focusable:focus,
.fw-form .datepicker .dropdown .input:focus,
.fw-form .datepicker .dropdown-trigger .input[readonly]:focus,
.fw-form select:focus,
.fw-form button:focus:not(.multiplechoice_option) {
  border-color: transparent;
  outline: solid 2px rgba(3, 164, 121, 0.7) !important;
  outline-offset: 2px !important;
  box-shadow: none;
}

.fw-form .error .textarea,
.fw-form .error .input,
.fw-form .error .datepicker .dropdown .input,
.fw-form .error .datepicker .dropdown-trigger .input[readonly],
.fw-form .error select,
.fw-form .error .multiselect {
  border-color: #f15f1fff !important;
  border-width: 1px !important;
  border-style: solid !important;
}

.input-r .input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.dropdown-trigger .control.has-icons-left .icon {
  top: 1px;
  height: 2.5em;
  width: 2.5em;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.dropdown-trigger .control.has-icons-left .input {
  padding-left: 2.5em;
}

.fw-form .datepicker .dropdown .input,
.fw-form .datepicker .dropdown-trigger .input[readonly] {
  background-color: #f5f5f5;
}

.fw-form .textarea,
.fw-form .input,
.fw-form .taginput .taginput-container.is-focusable {
  min-height: 35px;
  background-color: #f5f5f5;
}

.fw-form .datepicker .dropdown .input::placeholder,
.fw-form .datepicker .dropdown-trigger .input[readonly]::placeholder {
  color: rgba(108, 105, 105, 0.8);
}

.fw-form .textarea::placeholder,
.fw-form .input::placeholder,
.fw-form .taginput .taginput-container.is-focusable::placeholder {
  color: rgba(108, 105, 105, 0.8);
}

.fw-form .b-numberinput .control .button {
  height: 38px;
  width: 38px;
}

.fw-form input::placeholder {
  color: rgba(108, 105, 105, 0.8);
}

.fw-form input::-ms-input-placeholder {
  color: rgba(108, 105, 105, 0.8);
}
.fw-form .select:not(.is-multiple) {
  height: 2.75rem;
}
.fw-form .select select {
  height: 2.75rem;
}
.fw-form .datepicker-header .pagination-previous {
  width: 3rem;
  height: 2.75rem;
}
.fw-form .datepicker-header .pagination-next {
  width: 3rem;
  height: 2.75rem;
}
.fw-form .bg-saved {
  background-color: rgba(47, 199, 149, 0.25);
}

.fw-form.text15x .text-2xl {
  @apply text-3xl;
}
.fw-form.text15x .text-lg {
  @apply text-xl;
}
.fw-form.text15x .text-sm {
  @apply text-base;
}
.fw-form.text20x .text-2xl {
  @apply text-4xl;
}
.fw-form.text20x .text-lg {
  @apply text-2xl;
}
.fw-form.text20x .text-sm {
  @apply text-lg;
}
</style>

<i18n>
  {
    "en": {
      "deleteFileConfirm": "Are you sure you want to delete this file?"
    },
    "pt": {
      "deleteFileConfirm": "Tem certeza que deseja apagar este ficheiro?"
    }
  }
  </i18n>
