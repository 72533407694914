<template>
  <SidebarBase v-if="course">
    <template #main>
      <ButtonSidebar
        v-if="isManager"
        label="Modo de edição"
        icon="settings"
        bordered
        @click.native="goToManageCourseEdition"
      />

      <div class="mb-3">
        <div v-if="classroom || isManager">
          <div v-if="isManager">
            <div v-if="classes && classes.length > 1" class="mb-2">
              <fw-label class="mx-3">Turma</fw-label>
              <div>
                <b-dropdown aria-role="list" :scrollable="true" :max-height="200" expanded>
                  <fw-button-dropdown slot="trigger" aria-role="listitem" expanded type="light">
                    <v-clamp :max-lines="3" autoresize class="w-full">
                      {{ classroom.name }}
                    </v-clamp>
                  </fw-button-dropdown>
                  <b-dropdown-item
                    v-for="(classItem, classItemKey) in classes"
                    :key="classItemKey"
                    aria-role="listitem"
                    has-link
                    :focusable="false"
                    class="font-medium text-sm cursor-pointer px-1.5 py-1.5 items-center flex gap-2 border-b border-gray-100 hover:bg-gray-100"
                    @click.native="$emit('change-class', classItem.key)"
                  >
                    <div
                      class="h-2 w-2 bg-opacity-90 rounded-full flex-shrink-0"
                      :class="{
                        'bg-primary': classItem.key == activeClassKey
                      }"
                    ></div>
                    <v-clamp :max-lines="2" autoresize>
                      {{ classItem.name }}
                    </v-clamp>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
          <div v-else class="mx-3">
            <fw-label size="sm" class="flex gap-1 items-center" marginless> Turma </fw-label>
            <div class="font-semibold">
              <v-clamp :max-lines="3" autoresize>
                {{ classroom.name }}
              </v-clamp>
            </div>
          </div>

          <div v-if="classroom.meeting && classroom.meeting.key_alias" class="mt-2">
            <fw-button
              v-if="classroom.meeting && classroom.meeting.key_alias"
              type="light-primary"
              label="Sala virtual"
              expanded
              @click.native="goToMeeting"
            >
              <fw-icon-live class="w-5 h-5" />
              <div class="absolute flex top-1 right-1 items-center gap-1">
                <fw-dot v-if="classroom.meeting.running" inline />
                <fw-icon-arrow-right-up class="w-4 h-4" />
              </div>
            </fw-button>
          </div>
        </div>
      </div>

      <div v-if="validations.is_enrolled && validations.is_definitive" class="mx-2 mt-2 mb-4">
        <fw-label size="sm">O seu progresso</fw-label>
        <ProgressIcon
          :progress="progress"
          show-percentage
          percentage-pos="right"
          :color="
            !activeUnitKey || progress == 100
              ? 'bg-gradient-to-r from-teal-300 to-teal-400'
              : 'bg-gray-500 bg-opacity-80'
          "
        />
      </div>

      <ButtonSidebar
        :active="!view || view == 'dashboard'"
        label="Dashboard"
        @click.native="$emit('go-to-view', 'dashboard')"
      >
        <template #svg>
          <fw-icon-dashboard class="w-6 h-6" />
        </template>
      </ButtonSidebar>

      <ButtonSidebar
        :active="view == 'units' || view == 'unit'"
        :disabled="!(validations.is_enrolled && validations.is_definitive) && !validations.is_manager"
        label="Módulos"
        @click.native="$emit('go-to-unit', editionUnits[0]?.key)"
      >
        <template #svg>
          <fw-icon-book-mark-solid class="w-6 h-6" />
        </template>
      </ButtonSidebar>

      <ul v-if="editionUnits && activeUnitKey && (view == 'units' || view == 'unit')" class="ml-1.5">
        <li v-for="(unit, index) in editionUnits" :key="unit.key + '_' + edition.key" class="my-2">
          <button
            class="w-full font-medium transition-colors duration-150"
            :class="{
              'text-primary hover:text-primary':
                activeUnitKey &&
                activeUnitKey == unit.key &&
                ((validations.is_enrolled && validations.is_definitive) || validations.is_manager),
              'text-gray-500 hover:text-primary':
                (!activeUnitKey || activeUnitKey != unit.key) &&
                ((validations.is_enrolled && validations.is_definitive) || validations.is_manager),
              'text-gray-400 cursor-not-allowed':
                !(validations.is_enrolled && validations.is_definitive) && !validations.is_manager,
              'mb-2': activeUnitKey && activeUnitKey == unit.key
            }"
            :disabled="
              (!(validations.is_enrolled && validations.is_definitive) && !validations.is_manager) ||
                (activeUnitKey && activeUnitKey == unit.key)
            "
            @click="$emit('go-to-unit', unit.key)"
          >
            <div class="flex">
              <div class="ml-2 mr-2.5 opacity-50">
                <fw-icon-lock
                  v-if="!(validations.is_enrolled && validations.is_definitive) && !validations.is_manager"
                  class="w-5 h-5"
                />
                <fw-icon-list-view v-else class="w-5 h-5" />
              </div>
              <span class="hidden font-bold w-10 text-sm mr-1">M{{ index + 1 }}</span>
              <div class="flex flex-1 flex-col">
                <v-clamp class="w-full text-sm text-left" autoresize :max-lines="2">
                  {{ unit.title }}
                </v-clamp>
                <ProgressIcon
                  v-if="
                    activeUnitKey && activeUnitKey == unit.key && validations.is_enrolled && validations.is_definitive
                  "
                  :progress="unit.progress"
                  show-percentage
                  percentage-pos="right"
                  class="mt-1"
                  color="bg-primary bg-opacity-80"
                />
              </div>
            </div>
          </button>
        </li>
      </ul>

      <ButtonSidebar
        v-if="
          ((validations.is_enrolled && validations.is_definitive) || validations.is_manager) &&
            classroom.meeting &&
            classroom.meeting.key_alias
        "
        :active="view == 'chats'"
        label="Chat"
        @click.native="$emit('go-to-view', 'chats')"
      >
        <template #svg>
          <fw-icon-chats class="w-6 h-6" />
        </template>
      </ButtonSidebar>

      <ButtonSidebar
        :active="view == 'discussions'"
        :disabled="!validations.is_definitive && !validations.is_manager"
        icon="chat-thread"
        label="Fórum"
        @click.native="$emit('go-to-view', 'discussions')"
      />
      <ButtonSidebar :active="view == 'about'" label="Sobre o espaço" @click.native="$emit('go-to-view', 'about')">
        <template #svg>
          <fw-icon-article class="w-6 h-6" />
        </template>
      </ButtonSidebar>
    </template>
  </SidebarBase>
</template>

<script>
import SidebarBase from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarBase'
import ButtonSidebar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'
import ProgressIcon from '@/fw-modules/fw-core-vue/ui/components/ProgressIcon'

export default {
  components: {
    ButtonSidebar,
    SidebarBase,
    ProgressIcon
  },

  props: {
    // now: Object,
    course: Object,
    units: Array,
    progress: Number,
    edition: Object,
    validations: Object,
    classes: Array,
    activeUnitKey: String,
    activeClassKey: String,
    classroom: Object,
    view: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      openGoToSessionsManagerModal: false
    }
  },

  computed: {
    debug() {
      return Boolean(localStorage.getItem('fw-debug'))
    },
    now() {
      return this.$store.state.now
    },
    user() {
      return this.$store.getters.getUser
    },
    editionUnits() {
      if (this.units == null || typeof this.units == 'undefined') return []
      let units = this.units
      // units.sort((a, b) => a.index - b.index)
      console.log('sidebar units :>> ', units)
      return units
    },
    editions() {
      if (this.course.editions == null) return []
      return Object.values(this.course.editions).map(value => {
        return value
      })
    },

    // Permissions
    userRoles() {
      if (this.user && this.edition && this.edition.user && this.edition.user.roles) {
        return this.edition.user.roles
      }
      return []
    },
    // Course (all editions) manager
    isCourseManager() {
      for (const role of this.userRoles) {
        if (['admin'].includes(role)) return true
      }
      return false
    },
    // Edition manager (can access all classes)
    isCourseEditionManager() {
      return this.validations.is_manager
    },
    // Class manager / leader
    isCourseEditionClassManager() {
      return this.validations.is_leader
    },
    // Any manager role
    isManager() {
      return this.isCourseManager || this.isCourseEditionManager || this.isCourseEditionClassManager
    },
    isStudent() {
      return this.validations.is_student
    }
  },

  methods: {
    setActiveClass(activeClass) {
      this.$emit('set-active-class', activeClass.key)
    },
    setActiveEdition(activeEdition) {
      this.$emit('set-active-edition', activeEdition.key)
    },
    goToManageCourseEdition() {
      this.$router.push(`/manage/course/${this.course.key}/edition/${this.edition.course.edition.key}`)
    },
    goToMeeting() {
      if (!this.classroom?.meeting?.key_alias) return
      const routeData = this.$router.resolve({ name: 'live', params: { key: this.classroom.meeting.key_alias } })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
