<template>
  <div class="border-b border-gray-200 py-2">
    <div class="flex items-center">
      <div class="flex-1 text-lg font-bold text-black">
        {{ item.description[language] }}
      </div>
      <div class="portfolio-date">{{ date }}</div>
    </div>
    <div v-if="item.location && item.location.length > 0" class="portfolio-label">{{ item.location }}</div>
    <div v-if="item.competences.length > 0" class="flex flex-wrap gap-3">
      {{ $t('competencesdeveloped') }}
      <div v-for="(competence, c) in item.competences" :key="'item' + c" class="font-semibold text-primary">
        {{ competence.name[language] }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CareerItem',
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    date() {
      return this.item.date
        .split('-')
        .reverse()
        .join('/')
    },
    language() {
      return this.$store.state.language
    }
  }
}
</script>

<style scoped>
.aboutme-title {
  @apply text-primary font-semibold text-lg;
}
.portfolio-label {
  @apply font-semibold text-gray-500;
}
.portfolio-completed {
  @apply bg-primary text-sm rounded-full text-white px-3 py-0.5 font-semibold;
}
.portfolio-date {
  @apply bg-gray-500 text-sm rounded-full text-white px-3 py-0.5 font-semibold;
}
.aboutme-text {
  @apply text-black text-base;
}
</style>

<i18n>
  {
    "pt": {
      "completed": "Realizado",
      "planned": "Planeado",
      "competencesdeveloped": "Competências desenvolvidas",
      "competencestobedeveloped": "Competências a desenvolver",
      "label": {
        "pt": " em Português",
        "en": " em Inglês",
        "required": "Insira texto em Português e Inglês."
      }
    },
    "en": {
      "completed": "Completed",
      "planned": "Planned",
      "competencesdeveloped": "Competences developed",
      "competencestobedeveloped": "Competences to be developed",
      "save": "Save",
      "label": {
        "pt": " in Portuguese",
        "en": " in English",
        "required": "Insert text in Portuguese and English."
      }
    }
  }
</i18n>
