<template>
  <TapbarBase>
    <template #buttons>
      <ButtonTapbar to="/" :exact="true" label="Início">
        <template #svg>
          <fw-icon-home class="w-7 h-7" />
        </template>
      </ButtonTapbar>
      <ButtonTapbar to="/units" label="Disciplinas">
        <template #svg>
          <fw-icon-people class="w-7 h-7 p-0.5" />
        </template>
      </ButtonTapbar>
      <ButtonTapbar v-if="debugMode" to="/nondegree" label="Cursos">
        <template #svg>
          <fw-icon-b-learning-line class="w-7 h-7 p-0.5" />
        </template>
      </ButtonTapbar>
      <ButtonTapbar v-if="debugMode" to="/exams" label="Exames">
        <template #svg>
          <fw-icon-survey class="w-7 h-7" />
        </template>
      </ButtonTapbar>
      <ButtonTapbar to="/groups" label="Grupos">
        <template #svg>
          <fw-icon-group class="w-7 h-7" />
        </template>
      </ButtonTapbar>
      <ButtonTapbar to="/chats" label="Canais">
        <template #svg>
          <fw-icon-chats class="w-7 h-7" />
        </template>
      </ButtonTapbar>
      <ButtonTapbar to="/buckets" label="Ficheiros">
        <template #svg>
          <fw-icon-box-open class="w-7 h-7" />
        </template>
      </ButtonTapbar>
      <ButtonTapbar to="/account" label="Conta">
        <template #svg>
          <fw-icon-account-box class="w-7 h-7" />
        </template>
      </ButtonTapbar>
    </template>
  </TapbarBase>
</template>

<script>
import TapbarBase from '@/fw-modules/fw-core-vue/ui/components/tapbars/TapbarBase'
import ButtonTapbar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonTapbar'

export default {
  components: {
    ButtonTapbar,
    TapbarBase
  },
  data() {
    return {
      debugMode: Boolean(localStorage.getItem('fw-debug'))
    }
  }
}
</script>
