<template>
  <fw-modal
    v-if="showCompetenceModal"
    :active.sync="showCompetenceModal"
    :can-cancel="true"
    size="md"
    @close="showCompetenceModal = false"
  >
    <template #default>
      <div class="flex flex-col gap-3 text-left">
        <fw-heading size="h3">{{ $t('competence.add') }}</fw-heading>
        <div v-if="page == 'create'">
          <fw-label>{{ $t('description.label') }}</fw-label>
          <TranslatedInput
            v-model="newcompetence"
            :placeholder="{ pt: $t('competence.pt'), en: $t('competence.en') }"
            :disable-autowrite="true"
            :multiline="false"
            :class="{
              error: $v.newcompetence.$error
            }"
          />
          <fw-tip v-if="$v.newcompetence.$error" error>
            {{ $t('competence.required') }}
          </fw-tip>
          <fw-message>{{ $t('competence.warning') }}</fw-message>
        </div>
        <div v-else>
          <b-field :label="$t('competence.search')">
            <div class="relative">
              <b-input v-model="search" :placeholder="$t('competence.placeholder')"></b-input>
              <fw-icon-loading v-if="searching" class="w-6 h-6 absolute right-3 top-2.5" />
            </div>
          </b-field>
          <div
            v-for="result in searchResults"
            :key="'result' + result.key"
            class="p-4 border-b rounded-md leading-4 border-gray-100 cursor-pointer hover:bg-gray-100"
            @click="addCompetence(result)"
          >
            <b>{{ result.name.pt }}</b
            ><br />
            <span class="text-xs text-gray-500">{{ result.name.en }}</span>
          </div>
          <div
            v-if="search.length > 0"
            class="bg-gray-50 rounded-md p-5 mt-5 items-center gap-2 text-sm text-center flex flex-col text-gray-600"
          >
            {{ $t('competence.notfoundtitle') }}
            <fw-button type="light" @click.native="createPage()"> {{ $t('competence.notfoundbutton') }}</fw-button>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <fw-button v-if="page == 'create'" type="black" :loading="loading" expanded @click.native="createCompetence()">{{
        $t('create')
      }}</fw-button>
    </template>
  </fw-modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import ServicePages from '@/fw-modules/fw-core-vue/pages/services/ServicePages'
import _ from 'lodash'
import TranslatedInput from '@/fw-modules/fw-core-vue/ui/components/form/TranslatedInput'

export default {
  name: 'ModalCompetence',
  components: { TranslatedInput },
  data() {
    return {
      search: '',
      searchResults: [],
      page: 'search', // search, create
      newcompetence: {
        pt: '',
        en: ''
      },
      showCompetenceModal: false,
      loading: false,
      searching: false
    }
  },
  watch: {
    search() {
      console.log('search changed')
      this.debounceSearch()
    }
  },
  methods: {
    show() {
      this.showCompetenceModal = true
    },
    addCompetence(competence) {
      this.$emit('competence', competence)
      this.reset()
    },
    createCompetence() {
      this.$v.newcompetence.$touch()
      if (this.$v.newcompetence.$invalid) return
      this.loading = true
      ServicePages.competenceCreate(this.newcompetence.en, this.newcompetence.pt)
        .then(response => {
          console.log('competence create >> ', response)
          this.loading = false
          this.$emit('competence', response)
          this.reset()
        })
        .catch(error => {
          this.loading = false
          console.error(error)
        })
    },
    createPage() {
      this.page = 'create'
      this.newcompetence.pt = this.search
      this.newcompetence.en = this.search
    },
    reset() {
      this.newcompetence = {
        pt: '',
        en: ''
      }
      this.searchResults = []
      this.loading = false
      this.search = ''
      this.page = 'search'
      this.showCompetenceModal = false
      this.$v.$reset()
    },
    debounceSearch: _.debounce(function() {
      console.log('debouncing')
      if (this.searching || this.search == '') return
      this.searching = true
      ServicePages.competenceSearch(this.search)
        .then(response => {
          console.log('competence search >> ', response)
          this.searchResults = response
          this.searching = false
        })
        .catch(error => {
          this.searching = false
          console.error(error)
        })
    }, 500)
  },
  validations() {
    return {
      newcompetence: {
        pt: { required, min: minLength(1) },
        en: { required, min: minLength(1) }
      }
    }
  }
}
</script>

<i18n>
    {
      "pt": {
        "create": "Criar",
        "deleteFileConfirm": "Tem a certeza que deseja remover este ficheiro?",
        "competences": "Competências",
        "settings": "Configurações",
        "thereAreErrors": "Existem erros no formulário",
        "close": "Fechar",
        "edit": "Editar",
        "save": "Guardar",
        "delete": "Remover",
        "personalInfo": "Informação pessoal",
        "tournamentTitle": "Título",
        "competence": {
          "add": "Adicionar competência",
          "pt": "Nome da competência em Português",
          "en": "Nome da competência em Inglês",
          "required": "Insira o nome da competência em Português e Inglês.",
          "search": "Pesquisar competência",
          "warning": "Atenção que a competência criada irá ser partilhada com outros utilizadores da plataforma.",
          "placeholder": "Procure uma competência em Português ou Inglês",
          "notfoundtitle": "Não encontrou a competência que procurava?",
          "notfoundbutton": "Criar nova competência"
        },
        "description": {
          "label": "Descrição",
          "pt": "Descrição em Português",
          "en": "Descrição em Inglês",
          "required": "Insira uma descrição em Português e Inglês."
        }
      },
      "en": {
        "create": "Create",
        "competences": "Competences",
        "settings": "Settings",
        "generalDetails": "General details",
        "tournamentTitle": "Title",
        "thereAreErrors": "There are errors in the form",
        "personalInfo": "Personal info",
        "close": "Close",
        "edit": "Edit",
        "save": "Save",
        "delete": "Remove",
        "competence": {
          "add": "Add competence",
          "pt": "Name of the competence in Portuguese",
          "en": "Name of the competence in English",
          "required": "Insert the name of the competence in Portuguese and English",
          "search": "Search competence",
          "warning": "Attention that the created competence will be shared with other users of the platform.",
          "placeholder": "Search for a competence in Portuguese or English",
          "notfoundtitle": "Did not find the competence you were looking for?",
          "notfoundbutton": "Create new competence"
        },
        "description": {
          "label": "Description",
          "pt": "Description in Portuguese",
          "en": "Description in English",
          "required": "Enter a description in English and Portuguese."
        }
      }
    }
</i18n>
