<template>
  <div class="h-full flex flex-col">
    <div class="flex-1">
      <div v-if="!noTitle" class="flex items-center gap-3">
        <div class="font-bold text-2xl mb-3">
          {{ type == 'post' ? 'Atualizações' : 'Tópicos de discussão' }}
        </div>
        <div class="flex-1"></div>
        <OrderBy
          v-if="posts.length"
          :options="orderByOptions"
          :direction.sync="orderDirection"
          :order-items-by.sync="appliedSort"
          @sort-order-changed="sortChanged"
        />

        <slot name="header-end"></slot>
      </div>
      <BlockNewPostPlaceholder
        v-if="collectionKey"
        type="post"
        :collection="collectionKey"
        :users="users"
        class="border-b border-gray-100"
        @create="newPost()"
      />
      <div v-if="loading" class="text-gray-300 text-center py-14 rounded-b-xl flex justify-center">
        <fw-icon-loading class="w-8 h-8" />
      </div>
      <fw-panel v-else-if="posts.length == 0" boxed="sm">
        <div class="flex flex-col gap-5 items-center justify-center text-gray-500 text-sm font-medium py-10">
          <div>
            <fw-icon-chat-thread class="w-14 h-14 text-gray-300" />
          </div>
          <div>Não existem tópicos de discussão para apresentar.</div>
        </div>
      </fw-panel>
      <template v-else>
        <RecordPost
          v-for="(post, p) in posts"
          :key="post.key"
          minimal
          :paddingless="paddingless"
          :post="post"
          :users="users"
          class="cursor-pointer select-none rounded-lg overflow-hidden"
          :class="{ 'mb-5': p != post.length - 1 }"
          :type="post.type"
          @click.native="openPost(post)"
        />
      </template>
    </div>

    <BlockPagination
      v-if="pagination.total_pages > 1"
      :per-page="pagination.active_limit"
      :total="pagination.total_items"
      :total-pages="pagination.total_pages"
      :current.sync="pagination.current_page"
      @page-changed="changePage($event)"
    />

    <fw-modal
      v-if="showModalEditPost"
      :active.sync="showModalEditPost"
      :can-cancel="true"
      paddingless
      size="min"
      height-fit-screen
      @close="close"
    >
      <template #default>
        <BlockEditPost
          :collection-key="collectionKey"
          :context="'post'"
          :post="editing"
          :type="type"
          @saved="postSaved($event)"
          @close="close"
        />
      </template>
    </fw-modal>
  </div>
</template>

<script>
import BlockNewPostPlaceholder from '@/fw-modules/fw-core-vue/posts/components/blocks/BlockNewPostPlaceholder'
import RecordPost from '@/fw-modules/fw-core-vue/posts/components/records/RecordPost'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import ServicePosts from '@/fw-modules/fw-core-vue/posts/services/ServicePosts'
import BlockEditPost from '@/fw-modules/fw-core-vue/posts/components/blocks/BlockEditPost'
import OrderBy from '@/fw-modules/fw-core-vue/ui/components/search/OrderBy.vue'

export default {
  name: 'PanelPosts',

  components: {
    OrderBy,
    RecordPost,
    BlockPagination,
    BlockNewPostPlaceholder,
    BlockEditPost
  },

  props: {
    collectionKey: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'post'
    },
    collections: {
      type: Array,
      default: () => []
    },
    contextsData: {
      type: Object,
      default: () => {}
    },
    customkey: {
      type: String,
      default: ''
    },
    params: {
      type: Object,
      default: () => {}
    },
    noTitle: {
      type: Boolean,
      default: false
    },
    paddingless: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      posts: [],
      users: {},
      pagination: {
        current_page: 1,
        total_pages: 1,
        active_limit: 50,
        total_items: 1
      },
      loading: false,
      showModalEditPost: false,
      editing: null,
      appliedSort: {
        key: 'updated_date',
        label: 'Atividade',
        type: 'date'
      },
      orderDirection: 'desc',
      orderByOptions: [
        {
          key: 'created_date',
          label: 'Data de criação',
          type: 'date'
        },
        {
          key: 'updated_date',
          label: 'Atividade',
          type: 'date'
        },
        {
          key: 'popularity',
          label: 'Popularidade',
          type: 'number'
        }
      ]
    }
  },

  computed: {
    wsSubscription() {
      if (!this.collectionKey) return null
      return this.$store.state.socket.subscriptions?.[`content-${this.collectionKey}`]
    }
  },

  mounted() {
    this.restoreSort()
    this.loadPosts()
    this.subscribeWs()
  },

  beforeDestroy() {
    this.unsubscribeWs()
  },

  methods: {
    reload() {
      this.loadPosts()
    },
    addOrUpdatePost(post) {
      const index = this.posts.findIndex(p => p.key == post.key)
      if (index >= 0) {
        this.posts.splice(index, 1, post)
      } else {
        this.posts.unshift(post)
      }
    },
    handleWsMessages(messages) {
      // Can be: newPost, updatedPost, deletedPost, newReaction, deletedReaction
      console.log('messages :>> ', messages)
    },

    subscribeWs() {
      console.log('subscribeWs', this.collectionKey)
      if (!this.wsSubscription) {
        ServicePosts.createCollectionSubscription(this.collectionKey, this.handleWsMessages)
        window.addEventListener('beforeunload', this.unsubscribeWs, {
          capture: true
        })
      }
    },

    unsubscribeWs() {
      if (this.wsSubscription) {
        this.wsSubscription.destroy()
      }
    },

    changePage(page) {
      this.pagination.current_page = page
      this.loadPosts()
    },

    openPost(post) {
      this.$emit('open', post)
    },

    newPost() {
      this.editing = null
      this.showModalEditPost = true
    },

    editPost(post) {
      console.log('editPost', post)
      this.editing = post
      this.showModalEditPost = true
    },

    close() {
      this.showModalEditPost = false
    },

    sortChanged() {
      this.saveSort()
      this.loadPosts()
    },

    restoreSort() {
      const key = this.customKey ? window.location.pathname : this.customKey
      const sort = localStorage.getItem('sort:' + key)
      if (sort) {
        const savedSort = JSON.parse(sort)
        this.appliedSort = this.orderByOptions.find(o => o.key == savedSort.key)
        this.orderDirection = savedSort.direction
      }
    },

    saveSort() {
      const key = this.customKey ? window.location.pathname : this.customKey
      localStorage.setItem(
        'sort:' + key,
        JSON.stringify({ key: this.appliedSort?.key, direction: this.orderDirection })
      )
    },

    async loadPosts() {
      console.log('loadPosts', this.appliedSort)
      if (!this.collections.length && !this.collectionKey) return
      this.loading = true
      try {
        let data = {
          posts: [],
          users: {},
          pagination: {
            current_page: 1,
            total_pages: 1,
            active_limit: 50,
            total_items: 1
          }
        }
        if (this.collections.length > 0) {
          data = await ServicePosts.getFeed(
            this.collections.map(c => c.key),
            this.type,
            {
              ...this.params,
              page: this.pagination.current_page,
              limit: this.pagination.active_limit,
              sort: this.appliedSort?.key,
              direction: this.orderDirection
            }
          )
        } else if (this.collectionKey) {
          data = await ServicePosts.getCollectionPosts(this.collectionKey, this.type, {
            ...this.params,
            page: this.pagination.current_page,
            limit: this.pagination.active_limit,
            sort: this.appliedSort?.key,
            direction: this.orderDirection
          })
        }
        console.log('loadPosts :>> ', data)
        this.posts = data.posts
        this.users = data.users
        this.pagination = data.pagination
      } finally {
        this.loading = false
      }
    },

    async getPost(key, append = false) {
      if (!key) return

      try {
        let data = await ServicePosts.getPost(key)
        console.log('loadPost PanelPost :>> ', data)
        if (append) {
          this.posts.push(data.post)
        }
        return data
      } catch (error) {
        return null
      }
    },

    postSaved(post) {
      console.log('postSaved', post)
      if (post.key) {
        this.posts = this.posts.map(p => {
          if (p.key == post.key) {
            return post
          }
          return p
        })
      } else {
        this.posts.unshift(post)
      }
      this.close()
      this.openPost(post)
    }
  }
}
</script>
