var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fw-layout',{attrs:{"full":"","back-to-listen-event":"","mobile-ready":""},on:{"back":_vm.backTo},scopedSlots:_vm._u([{key:"main-sidebar",fn:function(){return [_c('SidebarClass',{attrs:{"edition":_vm.edition,"view":_vm.view,"profile":"student","teachers":_vm.teachers},on:{"go-to-view":_vm.goToView}})]},proxy:true},{key:"main-content",fn:function(){return [(_vm.view == 'liveclasses' && _vm.edition)?_c('PanelLiveClasses',{staticClass:"flex",attrs:{"edition":_vm.edition,"is-teacher":_vm.isTeacher,"view-list":_vm.viewList},on:{"load-session":function($event){_vm.viewList = !_vm.viewList},"go-to-user-chat":_vm.goToUserChat}}):_vm._e(),(_vm.view == 'teachers')?_c('PanelTeachers',{attrs:{"profile":"student","teachers":_vm.teachers},on:{"go-to-user-chat":_vm.goToUserChat}}):_vm._e(),(_vm.view == 'new-bucket')?_c('PanelBucketAdd',{staticClass:"mx-auto max-w-prose",attrs:{"application-context":{ application: 'academic', item_type: 'class_edition', item_key: _vm.edition.key }},on:{"go-back":function($event){_vm.view = 'buckets'}}}):_vm._e(),(_vm.view == 'buckets')?_c('fw-panel',{staticClass:"p-5",attrs:{"title":"Materiais de apoio","featured":""}},[_c('PanelBucketList',{attrs:{"paddingless":"","create-allowed":_vm.edition && _vm.edition.bucket_permissions.create,"application-context":{
          application: 'academic',
          item_type: 'class_edition', //, 'unit_edition'],
          include_unit_edition_buckets: _vm.edition !== null ? _vm.edition.unit.key : '',
          include_unit_buckets: _vm.edition !== null ? _vm.edition.unit.unit_key_for_bucket : '',
          item_key: _vm.edition.key
        }}})],1):_vm._e(),(_vm.inChatView)?_c('PanelChats',{ref:"chat",staticClass:"h-full",attrs:{"chats":_vm.chats,"chat-users":_vm.chatUsers,"all-chat-users":_vm.allChatUsers,"chat-active":_vm.chatActive,"view-chats-list":_vm.viewChatsList},on:{"load-chat":_vm.loadChatAndSetNav,"load-main-chat":_vm.loadMainChat,"unload-chat":_vm.unloadChatAndSetNav,"set-chat-messages":_vm.setChatMessages,"delete-chat-message":_vm.deleteChatMessage}}):_vm._e(),(_vm.view == 'calendar' && _vm.edition)?_c('PanelCalendar',{attrs:{"edition":_vm.edition}}):_vm._e()]},proxy:true},{key:"tapbar",fn:function(){return [_c('TapbarClass',{attrs:{"view":_vm.view,"is-teacher":_vm.isTeacher},on:{"go-to-view":_vm.goToView}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }