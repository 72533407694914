<template>
  <SidebarBase sticky>
    <template #main>
      <div class="flex-1">
        <ButtonSidebar
          :active="!view || view == 'dashboard'"
          :label="$t('dashboard')"
          icon="dashboard"
          @click.native="$emit('go-to-view', 'dashboard')"
        />

        <ButtonSidebar
          :active="view == 'personal' || view == 'about' || view == 'competences'"
          :label="$t('personal')"
          @click.native="$emit('go-to-view', 'personal')"
        >
          <template #svg>
            <fw-icon-cube class="w-6 h-6" />
          </template>
          <template #suffix>
            <fw-icon-chevron-up v-if="view == 'personal' || view == 'about' || view == 'competences'" class="w-4 h-4" />
            <fw-icon-chevron-down v-else class="w-4 h-4" />
          </template>
        </ButtonSidebar>

        <VerticalSteps
          v-if="view == 'personal' || view == 'about' || view == 'competences'"
          :steps="personalInfoSteps"
          :current-step="view ?? 'dashboard'"
          class="mb-2"
          :truncate-text="true"
          @clicked="$emit('go-to-view', $event)"
        />

        <ButtonSidebar
          :active="view == 'academic'"
          :label="$t('academic')"
          icon="graduation"
          @click.native="$emit('go-to-view', 'academic')"
        />
        <ButtonSidebar
          :active="view == 'career'"
          :label="$t('career')"
          icon="briefcase"
          @click.native="$emit('go-to-view', 'career')"
        />
        <ButtonSidebar
          :active="view == 'blog'"
          :label="$t('blog')"
          icon="quill"
          @click.native="$emit('go-to-view', 'blog')"
        />
        <ButtonSidebar
          v-if="false"
          :label="$t('activity')"
          :active="view == 'activity'"
          icon="activity"
          @click.native="$emit('go-to-view', 'activity')"
        />
        <ButtonSidebar
          :active="view == 'settings'"
          :label="$t('settings')"
          icon="settings"
          @click.native="$emit('go-to-view', 'settings')"
        />
      </div>
    </template>
  </SidebarBase>
</template>

<script>
import SidebarBase from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarBase'
import ButtonSidebar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'
import VerticalSteps from '@/fw-modules/fw-core-vue/ui/components/buttons/VerticalSteps'

export default {
  components: {
    ButtonSidebar,
    SidebarBase,
    VerticalSteps
  },

  props: {
    leagues: {
      type: Array,
      default: () => {}
    },
    checks: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      personalInfoSteps: [
        { value: 'about', text: this.$t('about_me') },
        { value: 'competences', text: this.$t('competences') }
      ]
    }
  },

  computed: {
    language() {
      return this.$store.state.language || 'pt'
    },
    view() {
      return this.$route.params.view ?? 'dashboard'
    }
  },

  methods: {}
}
</script>

<style lang="postcss">
a span:last-child {
  @apply text-black;
}
</style>

<i18n>
  {
    "pt": {
      "about_me": "Sobre mim",
      "competences": "Competências",
      "dashboard": "Dashboard",
      "personal": "Informação pessoal",
      "activity": "Atividade",
      "people": "Pessoas",
      "notifications": "Notificações",
      "settings": "Configurações",
      "metadata": "Metadados",
      "reports": "Mapas",
      "league": "Liga",
      "inbox": "Inbox",
      "bulkMessages": "Comunicações",
      "blog": "Blog",
      "academic": "Académico",
      "career": "Carreira"
    },
    "en": {
      "about_me": "About me",
      "competences": "Competences",
      "dashboard": "Dashboard",
      "personal": "Personal info",
      "activity": "Activity",
      "people": "People",
      "notifications": "Notifications",
      "settings": "Settings",
      "metadata": "Metadata",
      "reports": "Reports",
      "league": "League",
      "inbox": "Inbox",
      "bulkMessages": "Bulk Messages",
      "blog": "Blog",
      "academic": "Academic",
      "career": "Career"
    }
  }
  </i18n>
