<template>
  <fw-layout :back-to-enable="false" mobile-ready footer>
    <template v-if="manage" #main-sidebar>
      <PortfolioSidebar :view="manageView" @go-to-view="goToView($event)" />
    </template>

    <template #main-content>
      <div v-if="loading" class="flex flex-col gap-5">
        <div class="h-96 rounded-2xl animate-pulse bg-gray-300"></div>
        <div class="h-10 rounded-xl animate-pulse bg-gray-300 w-96"></div>
      </div>
      <PanelManagePortfolio
        v-else-if="
          portfolio && manage && (manageView == 'personal' || manageView == 'about' || manageView == 'competences')
        "
        :portfolio="portfolio"
        :users="users"
        :saving-data="savingData"
        @save-portfolio="savePortfolio"
      />
      <PanelManagePortfolioAcademic
        v-else-if="portfolio && manage && manageView == 'academic'"
        :portfolio="portfolio"
        :saving-data="savingData"
        :users="users"
        @save-portfolio="savePortfolio"
      />
      <PanelManagePortfolioCareer
        v-else-if="portfolio && manage && manageView == 'career'"
        :portfolio="portfolio"
        :saving-data="savingData"
        :users="users"
        @save-portfolio="savePortfolio"
      />
      <PanelManagePortfolioSettings
        v-else-if="portfolio && manage && manageView == 'settings'"
        :portfolio="portfolio"
        :saving-data="savingData"
        :users="users"
        @save-portfolio="savePortfolio"
        @publish-portfolio="publishPortfolio"
      />
      <PanelPortfolio
        v-else-if="(portfolio && !manage) || (portfolio && manage && manageView == 'dashboard')"
        :portfolio="portfolio"
        :editable="!manage && validations.can_edit"
        :users="users"
        @save-portfolio="savePortfolio"
      />
      <div v-else-if="portfolio != null" class="py-10 text-center text-gray-500 text-xl">{{ $t('soon') }}</div>
      <div v-else class="py-10 text-center text-gray-500 text-xl">{{ $t('not_found') }}</div>
    </template>
  </fw-layout>
</template>

<script>
import PanelManagePortfolio from '@/components/portfolio/manage/PanelManagePortfolio'
import PanelPortfolio from '@/components/portfolio/PanelPortfolio'
import PortfolioSidebar from '@/components/portfolio/sidebar/PortfolioSidebar'
import PanelManagePortfolioAcademic from '@/components/portfolio/manage/PanelManagePortfolioAcademic'
import PanelManagePortfolioCareer from '@/components/portfolio/manage/PanelManagePortfolioCareer'
import PanelManagePortfolioSettings from '@/components/portfolio/manage/PanelManagePortfolioSettings'
import ServicePages from '@/fw-modules/fw-core-vue/pages/services/ServicePages'
export default {
  components: {
    PanelManagePortfolio,
    PanelPortfolio,
    PortfolioSidebar,
    PanelManagePortfolioAcademic,
    PanelManagePortfolioCareer,
    PanelManagePortfolioSettings
  },
  data() {
    return {
      loading: true,
      portfolio: null,
      users: {},
      validations: {},
      savingData: false
    }
  },
  computed: {
    manageView() {
      return this.$route.params.view || 'dashboard'
    },
    portfolioKey() {
      return this.$route.params.key
    },
    manage() {
      return this.$route.meta.manage
    }
  },
  created() {
    this.loadPortfolio()
  },
  methods: {
    goToView(view) {
      this.$router.push({
        name: 'portfolio-manage-view',
        params: { key: this.portfolioKey, view }
      })
      if (this.manageView == 'about' || this.manageView == 'personal' || this.manageView == 'competences') {
        setTimeout(() => {
          location.hash = view == 'about' || view == 'competences' ? '#' + view : null
        }, 50)
      }
    },
    loadPortfolio() {
      this.loading = true
      ServicePages.getPortfolio(this.portfolioKey)
        .then(response => {
          let portfolio = response.portfolio
          if (!portfolio.options) {
            portfolio.options = {
              hasEnglish: false,
              background: 'abstract-01',
              backgroundImage: null,
              faculty: null
            }
          }
          if (!portfolio.about_me.hobbies) {
            portfolio.about_me.hobbies = {
              pt: '',
              en: ''
            }
          }
          if (!portfolio.about_me.interests) {
            portfolio.about_me.interests = {
              pt: '',
              en: ''
            }
          }
          this.portfolio = portfolio
          this.users = response.users
          this.validations = response.validations
          this.loading = false
        })
        .catch(error => {
          console.error(error)
          this.loading = false
        })
    },
    savePortfolio(data) {
      this.savingData = true
      let portfolio = JSON.parse(JSON.stringify(data))
      portfolio.competences = portfolio.competences.map(competence => competence.key)
      delete portfolio.contacts
      ServicePages.savePortfolio(this.portfolioKey, portfolio)
        .then(response => {
          console.log('savePortfolio >> ', response)
          this.portfolio = response.portfolio
          this.users = response.users
          this.savingData = false
          this.$buefy.snackbar.open({
            message: this.$t('data_saved'),
            type: 'is-primary',
            position: 'is-top-right',
            duration: 5000
          })
        })
        .catch(error => {
          console.error(error)
          this.savingData = false
        })
    },
    publishPortfolio(publish = true) {
      this.savingData = true
      ServicePages.publishPortfolio(this.portfolioKey, publish)
        .then(response => {
          console.log('publishPortfolio >> ', response)
          this.portfolio = response.portfolio
          this.users = response.users
          this.savingData = false
        })
        .catch(error => {
          console.error(error)
          this.savingData = false
        })
    }
  }
}
</script>

<i18n>
  {
    "pt": {
      "data_saved": "Dados guardados",
      "soon": "Brevemente",
      "not_found": "Portfolio não encontrado"
    },
    "en": {
      "data_saved": "Data saved",
      "soon": "Available soon",
      "not_found": "Portfolio not found"
    }
  }
</i18n>
