<template>
  <div class="relative">
    <fw-panel :title="$t('personalInfo')" featured :loading="savingData" after-loading-checked> </fw-panel>

    <LoadingPlaceholder v-if="loading || loadingFaculties" />

    <div v-else>
      <fw-panel id="about" :title="$t('aboutme')" boxed="lg" class="my-5" custom-class="bg-white flex flex-col gap-3">
        <template #toolbar>
          <div class="flex gap-1 items-center">
            <div v-if="$v.$error" class="p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-medium">
              <fw-icon-error-warning class="w-6 h-6"></fw-icon-error-warning>
              <span class="hidden lg:block">{{ $t('thereAreErrors') }}</span>
            </div>
            <div class="flex gap-5">
              <fw-button
                :type="isDirty ? 'primary' : 'light'"
                :disabled="savingData"
                :loading="savingData"
                @click.native="savePortfolio()"
                >{{ $t('save') }}</fw-button
              >
            </div>
          </div>
        </template>
        <div>
          <fw-label>{{ $t('faculty') }}</fw-label>
          <div v-if="faculties.length > 0" class="flex flex-col gap-2 mb-2">
            <div
              v-for="(faculty, f) in faculties"
              :key="'fac' + f"
              class=" bg-gray-50 rounded-md flex items-center py-0.5 pl-3 pr-1"
            >
              <div class="flex-1">
                {{ faculty.name[language] }}
              </div>
              <fw-button @click.native="removeFaculty(f)">{{ $t('delete') }}</fw-button>
            </div>
          </div>
          <b-select
            v-if="filteredFacultyOptions.length > 0"
            v-model="selectedFaculty"
            :placeholder="$t('choose_faculty')"
            @input="facultyChanged"
          >
            <option value="none" disabled>{{ $t('add_faculty') }}</option>
            <option v-for="(faculty, f) in filteredFacultyOptions" :key="'faculty' + f" :value="faculty">{{
              faculty.name[language]
            }}</option>
          </b-select>
        </div>
        <div>
          <fw-label>{{ $t('shortdescription.label') }}</fw-label>
          <TranslatedInput
            v-if="portfolio.options.hasEnglish"
            v-model="about_me.short_description"
            :placeholder="{ pt: $t('shortdescription.pt'), en: $t('shortdescription.en') }"
            :disable-autowrite="true"
            :multiline="false"
            :class="{
              error: $v.about_me.short_description.$error
            }"
            @input="isDirty = true"
          />
          <b-input
            v-else
            v-model="about_me.short_description.pt"
            maxlength="500"
            type="textarea"
            @input="isDirty = true"
          ></b-input>
          <fw-tip v-if="$v.about_me.short_description.$error" error>
            {{ $t('shortdescription.required') }}
          </fw-tip>
        </div>
        <div>
          <fw-label v-if="!portfolio.options.hasEnglish">{{ $t('description.label') }}</fw-label>

          <div v-if="portfolio.options.hasEnglish">
            <fw-label>{{ $t('description.pt') }}</fw-label>
            <fw-editor v-model="about_me.description['pt']" @input="isDirty = true"></fw-editor>
            <fw-label>{{ $t('description.en') }}</fw-label>
            <fw-editor v-model="about_me.description['en']" @input="isDirty = true"></fw-editor>
          </div>
          <fw-editor v-else v-model="about_me.description.pt" @input="isDirty = true"></fw-editor>
          <fw-tip v-if="$v.about_me.description.$error" error>
            {{ $t('description.required') }}
          </fw-tip>
        </div>

        <div>
          <fw-label v-if="!portfolio.options.hasEnglish">{{ $t('hobbies.label') }}</fw-label>
          <div v-if="portfolio.options.hasEnglish">
            <fw-label>{{ $t('hobbies.pt') }}</fw-label>
            <fw-editor v-model="about_me.hobbies['pt']" @input="isDirty = true"></fw-editor>
            <fw-label>{{ $t('hobbies.en') }}</fw-label>
            <fw-editor v-model="about_me.hobbies['en']" @input="isDirty = true"></fw-editor>
          </div>
          <fw-editor v-else v-model="about_me.hobbies.pt" @input="isDirty = true"></fw-editor>
        </div>

        <div>
          <fw-label v-if="!portfolio.options.hasEnglish">{{ $t('interests.label') }}</fw-label>
          <div v-if="portfolio.options.hasEnglish">
            <fw-label>{{ $t('interests.pt') }}</fw-label>
            <fw-editor v-model="about_me.interests['pt']" @input="isDirty = true"></fw-editor>
            <fw-label>{{ $t('interests.en') }}</fw-label>
            <fw-editor v-model="about_me.interests['en']" @input="isDirty = true"></fw-editor>
          </div>
          <fw-editor v-else v-model="about_me.interests.pt" @input="isDirty = true"></fw-editor>
        </div>
      </fw-panel>
      <fw-panel
        id="competences"
        :title="$t('competences')"
        boxed="lg"
        class="my-5"
        custom-class="bg-white flex flex-col gap-3"
      >
        <Container
          v-if="portfolio.competences.length > 0"
          :group-name="'competences'"
          drop-class="card-ghost-drop"
          :drop-placeholder="dropPlaceholderOptions"
          :should-animate-drop="() => true"
          :get-child-payload="index => getChildPayload(index)"
          drag-handle-selector=".drag-handle"
          class="flex flex-col bg-white rounded-md"
          @drop="onDropSection($event)"
        >
          <Draggable
            v-for="(competence, c) in portfolio.competences"
            :key="competence.key"
            class="relative overflow-visible py-2"
          >
            <div class="flex gap-4">
              <div class="drag-handle">
                <svg
                  class="fill-current h-3 w-3"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 10 16"
                  width="10"
                  height="16"
                >
                  <path
                    fill-opacity="0.13"
                    d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                  />
                </svg>
              </div>
              <div class="flex-1 pl-8">
                <div class=" text-lg font-semibold">
                  {{ competence.name.pt }}
                </div>
                <div v-if="portfolio.options.hasEnglish" class="text-sm text-gray-500">
                  {{ competence.name.en }}
                </div>
              </div>
              <div class=" flex-shrink-0">
                <b-dropdown aria-role="list" position="is-bottom-left">
                  <template #trigger="{ active }">
                    <fw-button type="white" size="sm"> <fw-icon-more class="w-5 h-5 text-gray-500"/></fw-button>
                  </template>
                  <b-dropdown-item paddingless aria-role="listitem">
                    <fw-button type="white-gray" class="w-full text-left" @click.native="remove(c)">{{
                      $t('delete')
                    }}</fw-button></b-dropdown-item
                  >
                </b-dropdown>
              </div>
            </div>
          </Draggable>
        </Container>
        <div v-else class="text-gray-400 mb-3 text-sm">{{ $t('no_competences') }}</div>
        <fw-button type="primary" @click.native="showCompetenceModal">{{ $t('add_competence') }}</fw-button>
      </fw-panel>

      <ModalCompetence ref="modalcompetence" @competence="addCompetence" />
    </div>
  </div>
</template>

<script>
import TranslatedInput from '@/fw-modules/fw-core-vue/ui/components/form/TranslatedInput'
import ModalCompetence from '@/components/modals/ModalCompetence'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import { Container, Draggable } from 'vue-dndrop'
import { required, minLength } from 'vuelidate/lib/validators'
import ServicePages from '@/fw-modules/fw-core-vue/pages/services/ServicePages'

export default {
  components: {
    TranslatedInput,
    LoadingPlaceholder,
    Container,
    Draggable,
    ModalCompetence
  },

  props: {
    portfolio: {
      type: Object,
      default: () => {
        return {}
      }
    },

    savingData: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      faculties: [],
      about_me: {},
      loadingFaculties: true,
      dropPlaceholderOptions: {
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true
      },
      newcompetence: {
        pt: '',
        en: ''
      },
      facultyOptions: [],
      selectedFaculty: 'none',
      isDirty: false
    }
  },

  computed: {
    filteredFacultyOptions() {
      return this.facultyOptions.filter(f => !this.faculties.find(fac => fac.short_name === f.short_name))
    },
    api() {
      return this.$store.state.api.base
    },

    language() {
      return this.$store.state.language
    },

    secondaryLanguage() {
      return this.language === 'pt' ? 'en' : 'pt'
    }
  },

  created() {
    this.about_me = this.portfolio.about_me
    if (this.portfolio.options.faculties) {
      this.faculties = this.portfolio.options.faculties
    }
    this.loadFaculties()
  },

  mounted() {
    this.about_me = this.portfolio.about_me
    if (this.portfolio.options.faculties) {
      this.faculties = this.portfolio.options.faculties
    }
  },

  validations() {
    return {
      about_me: {
        short_description: {
          pt: { required, min: minLength(1) }
        },
        description: {
          pt: { required, min: minLength(1) }
        }
        /*hobbies: {
            pt: { required, min: minLength(1) },
            en: { required, min: minLength(1) },
          },
          interests: {
            pt: { required, min: minLength(1) },
            en: { required, min: minLength(1) },
          },*/
      },
      newcompetence: {
        pt: { required, min: minLength(1) },
        en: { required, min: minLength(1) }
      }
    }
  },

  methods: {
    removeFaculty(i) {
      this.faculties.splice(i, 1)
      this.isDirty = true
    },
    loadFaculties() {
      this.loadingFaculties = true
      ServicePages.getOrganicUnits()
        .then(response => {
          this.facultyOptions = response
          this.loadingFaculties = false
        })
        .catch(error => {
          console.error(error)
          this.loadingFaculties = false
        })
    },
    facultyChanged(faculty) {
      console.log('facultyChanged >> ', faculty)
      if (faculty === 'none') return
      if (this.faculties.find(f => f.short_name === faculty.short_name)) return
      this.faculties.push(faculty)
      this.isDirty = true
      this.selectedFaculty = 'none'
    },
    showCompetenceModal() {
      console.log(this.$refs.modalcompetence)
      this.$refs.modalcompetence.show()
    },
    remove(i) {
      this.$buefy.dialog.confirm({
        title: this.language == 'pt' ? 'Remover competência' : 'Remove competence',
        cancelText: this.language == 'pt' ? 'Cancelar' : 'Cancel',
        confirmText: this.language == 'pt' ? 'Remover' : 'Remove',
        message:
          this.language == 'pt'
            ? 'Tem a certeza que deseja remover esta competência?'
            : 'Are you sure you want to remove this competence?',
        onConfirm: () => {
          this.portfolio.competences.splice(i, 1)
          this.saveCompetences()
        }
      })
    },
    onDropSection(event) {
      let result = this.applyDrag(this.portfolio.competences, event)
      console.log('applyDrag', result)
      this.portfolio.competences = result
      this.saveCompetences()
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },
    getChildPayload(index) {
      return this.portfolio.competences[index]
    },
    savePortfolio() {
      this.$v.about_me.$touch()
      if (this.$v.about_me.$invalid) return
      let portfolio = JSON.parse(JSON.stringify(this.portfolio))
      portfolio.about_me = this.about_me
      portfolio.options.faculties = this.faculties

      //replace competences by key
      portfolio.academic_plan.items.forEach((item, i) => {
        item.curriculum.forEach((curriculum, c) => {
          portfolio.academic_plan.items[i].curriculum[c].competences.forEach((competence, cp) => {
            portfolio.academic_plan.items[i].curriculum[c].competences[cp] = competence.key
          })
        })

        item.extra_curriculum.forEach((extra_curriculum, c) => {
          portfolio.academic_plan.items[i].extra_curriculum[c].competences.forEach((competence, cp) => {
            portfolio.academic_plan.items[i].extra_curriculum[c].competences[cp] = competence.key
          })
        })
      })
      portfolio.carrer_development.professional_experience = portfolio.carrer_development.professional_experience.map(
        item => {
          item.competences = item.competences.map(competence => competence.key)
          return item
        }
      )
      portfolio.carrer_development.volunteering = portfolio.carrer_development.volunteering.map(item => {
        item.competences = item.competences.map(competence => competence.key)
        return item
      })
      portfolio.carrer_development.certifications = portfolio.carrer_development.certifications.map(item => {
        item.competences = item.competences.map(competence => competence.key)
        return item
      })
      this.isDirty = false
      this.$emit('save-portfolio', portfolio)
    },
    saveCompetences() {
      let portfolio = JSON.parse(JSON.stringify(this.portfolio))
      //replace competences by key
      portfolio.academic_plan.items.forEach((item, i) => {
        item.curriculum.forEach((curriculum, c) => {
          portfolio.academic_plan.items[i].curriculum[c].competences.forEach((competence, cp) => {
            portfolio.academic_plan.items[i].curriculum[c].competences[cp] = competence.key
          })
        })

        item.extra_curriculum.forEach((extra_curriculum, c) => {
          portfolio.academic_plan.items[i].extra_curriculum[c].competences.forEach((competence, cp) => {
            portfolio.academic_plan.items[i].extra_curriculum[c].competences[cp] = competence.key
          })
        })
      })
      portfolio.carrer_development.professional_experience = portfolio.carrer_development.professional_experience.map(
        item => {
          item.competences = item.competences.map(competence => competence.key)
          return item
        }
      )
      portfolio.carrer_development.volunteering = portfolio.carrer_development.volunteering.map(item => {
        item.competences = item.competences.map(competence => competence.key)
        return item
      })
      portfolio.carrer_development.certifications = portfolio.carrer_development.certifications.map(item => {
        item.competences = item.competences.map(competence => competence.key)
        return item
      })
      this.$emit('save-portfolio', portfolio)
    },

    addCompetence(competence) {
      console.log('addCompetence >> ', competence)
      if (this.portfolio.competences.find(c => c.key == competence.key)) return
      this.portfolio.competences.push(competence)
      this.saveCompetences()
      /*this.competences.push({
        key: this.competences.length + 1,
        pt: this.newcompetence.pt,
        en: this.newcompetence.en,
        endorsements: [],
      })*/
    }
  }
}
</script>
<i18n>
  {
    "pt": {
      "add_competence": "Adicionar competência",
      "no_competences": "Competências não definidas",
      "faculty": "Faculdades",
      "add_faculty": "Adicionar faculdade",
      "choose_faculty": "Escolhe a tua faculdade",
      "aboutme": "Sobre mim",
      "competences": "Competências",
      "settings": "Configurações",
      "thereAreErrors": "Existem erros no formulário",
      "close": "Fechar",
      "edit": "Editar",
      "save": "Guardar",
      "delete": "Remover",
      "personalInfo": "Informação pessoal",
      "tournamentTitle": "Título",
      "competence": {
        "pt": "Nome da competência em Português",
        "en": "Nome da competência em Inglês",
        "required": "Insira o nome da competência em Português e Inglês."
      },
      "description": {
        "label": "Apresentação pessoal",
        "pt": "Apresentação pessoal em Português",
        "en": "Apresentação pessoal em Inglês",
        "required": "Insira uma apresentação em Português e Inglês."
      },
      "interests": {
        "label": "Interesses",
        "pt": "Interesses em Português",
        "en": "Interesses em Inglês",
        "required": "Insira os seus interesses em Português e Inglês."
      },
        "hobbies": {
            "label": "Hobbies",
            "pt": "Hobbies em Português",
            "en": "Hobbies em Inglês",
            "required": "Insira os seus hobbies em Português e Inglês."
        },
        "shortdescription": {
            "label": "Descrição curta",
            "pt": "Descrição curta em Português",
            "en": "Descrição curta em Inglês",
            "required": "Insira uma descrição curta em Português e Inglês."
        },
      "charsLimit": "Limite de {limit} caracteres.",
      "notDefined": "Não definido"
    },
    "en": {
      "add_competence": "Add competence",
      "no_competences": "No competences defined",
      "add_faculty": "Add faculty",
      "choose_faculty": "Choose your faculty",
      "faculty": "Faculties",
      "aboutme": "About me",
      "competences": "Competences",
      "settings": "Settings",
      "generalDetails": "General details",
      "tournamentTitle": "Title",
      "thereAreErrors": "There are errors in the form",
      "personalInfo": "Personal info",
      "close": "Close",
      "edit": "Edit",
      "save": "Save",
      "delete": "Remove",
      "competence": {
        "pt": "Name of the competence in Portuguese",
        "en": "Name of the competence in English",
        "required": "Insert the name of the competence in Portuguese and English"
      },
      "description": {
        "label": "Personal presentation",
        "pt": "Personal presentation in Portuguese",
        "en": "Personal presentation in English",
        "required": "Enter a personal presentation in English and Portuguese."
      },
      "interests": {
        "label": "Interests",
        "pt": "Interests in Portuguese",
        "en": "Interests in English",
        "required": "Enter your interests in English and Portuguese."
      },
      "hobbies": {
        "label": "Hobbies",
        "pt": "Hobbies in Portuguese",
        "en": "Hobbies in English",
        "required": "Enter your hobbies in English and Portuguese."
      },
      "shortdescription": {
        "label": "Short description",
        "pt": "Short description in Portuguese",
        "en": "Short description in English",
        "required": "Enter a short description in English and Portuguese."
      },
      "charsLimit": "Limit of {limit} characters.",
      "notDefined": "Not defined"
    }
  }
  </i18n>

<style>
.drag-handle {
  @apply h-6 w-5 shadow-sm absolute top-3 left-1 bg-white rounded border border-gray-200 items-center flex justify-center text-center;
  cursor: grab;
}
.dndrop-container.vertical > .dndrop-draggable-wrapper {
  overflow: visible !important;
}
</style>
