<template>
  <fw-layout
    :full="isFullLayout"
    :loading="loading"
    :notfound="notfound"
    :forbidden="!loading && forbidden"
    :management="validations?.is_manager"
    :back-to-home-manage="courseEditionEditPath"
    :inner-content-scrollable="view == 'unit'"
    :main-content-scrollable="view != 'unit'"
    main-listbar-width="w-[23rem]"
    back-to="/"
  >
    <template v-if="edition && courseEdition?.course" #header-nav>
      <div class="flex gap-5 items-center">
        <div>
          <div>
            <v-clamp
              v-if="courseEdition?.course?.title"
              autoresize
              :max-lines="1"
              class="font-semibold text-md leading-5 max-w-xs"
            >
              {{ courseEdition.course.title }}
            </v-clamp>
            <span v-else class="text-gray-500 text-sm">Espaço sem título</span>
          </div>
          <div class="text-sm font-semibold text-gray-500">
            <v-clamp autoresize :max-lines="1" class="font-medium text-sm leading-5 max-w-sm">
              {{ edition?.title }}
            </v-clamp>
          </div>
        </div>
        <div>
          <fw-tag v-if="isEnrollmentDefinitive && isEditionRunning" size="sm" type="light-primary" custom-class="px-3">
            Em curso
          </fw-tag>
          <fw-tag
            v-else-if="!isEnrollmentDefinitive && isSignUpOpen"
            size="sm"
            type="light-orange"
            custom-class="px-3"
            class="hidden"
          >
            Inscrições abertas
          </fw-tag>
        </div>
      </div>
    </template>

    <template #main-sidebar>
      <SidebarCourseEdition
        :key="courseEdition.course.key + '-' + courseEdition.course.edition.key + '-' + units.length"
        :course="course"
        :units="units"
        :view="view"
        :edition="courseEdition"
        :progress="progress"
        :classroom="userData.class"
        :classes="userData.classes || []"
        :validations="validations"
        :active-class-key="activeClassKey"
        :active-unit-key="activeUnit ? activeUnit.key : null"
        @set-active-class="changeClass($event)"
        @set-active-edition="changeEdition($event)"
        @change-class="changeClass($event)"
        @open-management="openManagement()"
        @go-to-view="goToView($event)"
        @go-to-unit="goToView('unit', $event)"
      />
    </template>

    <template v-if="view == 'unit' && unitsLoaded && activeUnit && checks.isCourseEditionUnlocked" #main-listbar>
      <SidebarCourseEditionUnits
        :key="activeUnit.key + '-' + activeUnit.topics.length"
        :course="courseEdition.course"
        :edition="courseEdition.course.edition"
        :checks="checks"
        :unit="activeUnit"
        :is-manager="isManager"
      />
    </template>

    <template #main-content>
      <PanelDashboard
        v-if="!view || view == 'dashboard'"
        :course="courseEdition.course"
        :progress="progress"
        :edition.sync="courseEdition.course.edition"
        :units="courseEdition.units"
        :people="courseEdition.people"
        :user-data="courseEdition.user"
        :checks="checks"
        :remaining-seconds="remainingSeconds"
        @sign-up="startSignup()"
        @go-to-unit="goToView('unit', $event)"
      />
      <div v-else-if="view == 'unit' && unitsLoaded && activeUnit" class="w-full">
        <template v-if="checks.isCourseEditionUnlocked">
          <PanelUnit
            v-if="!activeTopicKey"
            :key="activeUnit.key"
            ref="panelUnit"
            :course="courseEdition.course"
            :edition="courseEdition.course.edition"
            :unit="activeUnit"
            :users="users"
            :instructors="instructors"
            :collections="unitCollections"
            :checks="checks"
            :is-manager="isManager"
            @update-course-progress="updateCourseProgress"
            @update-unit-progress="updateUnitProgress"
          />
          <PanelUnitTopic
            v-else-if="activeTopicKey"
            :key="activeTopicKey"
            ref="panelTopic"
            :course="courseEdition.course"
            :edition="courseEdition.course.edition"
            :unit="activeUnit"
            :checks="checks"
            :is-manager="isManager"
            @update-course-progress="updateCourseProgress"
            @update-unit-progress="updateUnitProgress"
          />
        </template>
        <div v-else class="w-full h-full flex flex-col py-10 text-center text-gray-300">
          O conteúdo do espaço ainda não está disponível.
        </div>
      </div>
      <div
        v-else-if="view == 'unit' && unitsLoaded && !activeUnit"
        class="h-full w-full flex flex-col gap-5 items-center justify-center text-gray-500 text-sm"
      >
        <fw-icon-cube class="w-12 h-12" />
        Espaço sem módulos definidos.
      </div>
      <PanelCourseEditionPosts
        v-else-if="view === 'posts' || view === 'discussions'"
        :key="view"
        :course="courseEdition.course"
        :edition="courseEdition.course.edition"
        :collections="postCollections"
        :view="view"
      />
      <PanelAbout v-else-if="view == 'about'" :course="course" :units="units" :edition="edition" />
      <PanelCourseEditionChats
        v-else-if="view == 'chats'"
        :course-key="courseKey"
        :edition-key="editionKey"
        :class-key="activeClassKey"
      />

      <!-- <fw-panel-info debug label="Data (raw)">
        <json-viewer
          :value="{
            courseEdition,
            activeUnit,
            activeUser,
            classProgress,
            instructors,
            users,
            unitCollections
          }"
        ></json-viewer>
      </fw-panel-info> -->
    </template>

    <template #modals>
      <b-modal
        :width="850"
        class="rounded-buefy-modal"
        :can-cancel="true"
        :active.sync="openEnrollmentModal"
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        scroll="keep"
      >
        <ModalCourseEnrollment
          :course="courseEdition.course"
          :edition="courseEdition.course.edition"
          :enrollment="courseEdition.enrollment"
          @close="closeSignupModal"
          @success="loadCourseEdition()"
        ></ModalCourseEnrollment>
      </b-modal>
      <b-modal
        :active="activeUser !== null"
        scroll="keep"
        :can-cancel="true"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :width="700"
        :on-cancel="closeUserDetails"
        :custom-class="'rounded-buefy-modal'"
      >
        <UserDetails
          v-if="activeUser != null"
          :user="activeUser"
          @updated-user="activeUser = $event"
          @close="activeUser = null"
        >
          <template v-if="activeUser != null" #more-meta>
            <!-- Course progress -->
            <div>
              <div>
                <fw-label marginless>Estado da conta</fw-label>
                <div
                  v-if="activeUser.activated_date != null"
                  class="font-semibold text-primary flex items-center gap-1"
                >
                  <span>Conta ativa</span>
                  <fw-icon-check class="h-5"></fw-icon-check>
                </div>
                <div v-else>Conta inativa</div>
              </div>

              <div>
                <fw-label>Data de inscrição na edição do espaço</fw-label>
                <div v-if="activeUser.enrollment && activeUser.enrollment.date">
                  {{ activeUser.enrollment.date | formatDate }}
                </div>
                <div v-else class="text-gray-500 text-sm">Não inscrito.</div>
              </div>
            </div>
          </template>
          <template v-if="activeUser != null" #default>
            <fw-panel title="Progresso no espaço" subtitle="Por módulo" class="my-5">
              <template #toolbar>
                <div class="flex-shrink-0 w-56 mx-auto">
                  <fw-label size="xs">Progresso geral</fw-label>
                  <ProgressIcon
                    :progress="userProgress.progress"
                    show-percentage
                    percentage-pos="right"
                    :color="
                      userProgress.progress == 100
                        ? 'bg-gradient-to-r from-teal-300 to-teal-400'
                        : 'bg-gray-500 bg-opacity-80'
                    "
                  />
                </div>
              </template>
              <template #default>
                <div class="mt-5">
                  <div v-for="unit in userProgress.units" :key="unit.index" class="my-5">
                    <div class="flex gap-5">
                      <div class="flex-1 flex gap-2">
                        <div class="my-1 flex-shrink-0">
                          <fw-icon-book-mark-solid class="w-5 h-5" />
                        </div>
                        <div>
                          <v-clamp
                            class="w-full flex-1 text-left font-bold text-lg items-end flex"
                            autoresize
                            :max-lines="3"
                          >
                            {{ unit.name }}
                          </v-clamp>
                        </div>
                      </div>
                      <div class="w-44 flex-shrink-0 my-1">
                        <ProgressIcon
                          :progress="unit.progress"
                          show-percentage
                          percentage-pos="right"
                          :color="
                            unit.progress == 100
                              ? 'bg-gradient-to-r from-teal-300 to-teal-400'
                              : 'bg-gray-500 bg-opacity-80'
                          "
                        />
                      </div>
                    </div>
                    <div class="flex flex-col mt-3">
                      <div
                        v-for="topic in unit.topics"
                        :key="topic.key"
                        class="flex items-center gap-3 p-3 border-b hover:bg-gray-100"
                      >
                        <faicon v-if="topic.seen_date !== null" class="text-primary" icon="check-circle" />
                        <faicon v-else class="text-gray-200" icon="circle" />
                        <div class="flex-1 font-medium">
                          <v-clamp class="w-full flex-1 text-left items-end flex" autoresize :max-lines="3">
                            {{ topic.title }}
                          </v-clamp>
                          <div v-if="topic.seen_date != null" class="text-sm text-gray-500">
                            Concluído a {{ topic.seen_date | formatDateTime }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </fw-panel>
          </template>
        </UserDetails>
      </b-modal>
    </template>
  </fw-layout>
</template>

<script>
import ServiceCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import ModalCourseEnrollment from '@/fw-modules/fw-core-vue/nondegree-courses/components/modals/ModalCourseEnrollment'
import SidebarCourseEdition from '@/fw-modules/fw-core-vue/courses/components/sidebars/SidebarCourseEdition'
import PanelAbout from '@/fw-modules/fw-core-vue/courses/components/panels/PanelAbout'
import PanelDashboard from '@/fw-modules/fw-core-vue/courses/components/panels/PanelDashboard'
import SidebarCourseEditionUnits from '@/fw-modules/fw-core-vue/courses/components/sidebars/SidebarCourseEditionUnits'
import PanelUnit from '@/fw-modules/fw-core-vue/courses/components/panels/PanelUnit'
import PanelUnitTopic from '@/fw-modules/fw-core-vue/courses/components/panels/PanelUnitTopic'
import UserDetails from '@/components/modals/UserDetails'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import PanelCourseEditionPosts from '@/fw-modules/fw-core-vue/courses/components/panels/PanelCourseEditionPosts.vue'
import PanelCourseEditionChats from '@/fw-modules/fw-core-vue/courses/components/panels/PanelCourseEditionChats.vue'

export default {
  components: {
    ModalCourseEnrollment,
    SidebarCourseEdition,
    SidebarCourseEditionUnits,
    PanelUnit,
    PanelAbout,
    PanelUnitTopic,
    PanelDashboard,
    UserDetails,
    PanelCourseEditionPosts,
    PanelCourseEditionChats
  },

  data() {
    return {
      remainingSeconds: 0,
      courseEdition: null,
      activeUnit: null,
      forbidden: false,
      openEnrollmentModal: false,
      // view: this.$route.params.unitKey ? 'unit' : this.$route.params.subpage,
      activeUser: null,
      loading: true,
      loadingUserDetails: false,
      classProgress: null,
      unitsLoaded: false,
      instructors: {},
      users: {},
      timerInterval: null,
      unitCollections: [],
      wsSubscription: null,

      localActiveClassKey: this.$route.query.class_key || null
    }
  },
  computed: {
    view() {
      return this.$route.params.unitKey ? 'unit' : this.$route.params.subpage
    },
    courseKey() {
      return this.$route.params.courseKey || null
    },
    editionKey() {
      return this.$route.params.editionKey || null
    },
    activeClassKey() {
      return this.$route.query.class_key || this.localActiveClassKey
    },
    activeTopicKey() {
      return this.$route.params.topicKey
    },
    activeUnitKey() {
      return this.$route.params.unitKey
    },
    postCollections() {
      return this.courseEdition && this.courseEdition.user ? this.courseEdition.user.class.collections : []
    },
    course() {
      return this.courseEdition?.course
    },
    userData() {
      return this.courseEdition?.user
    },
    notfound() {
      return !this.loading && !this.edition
    },
    progress() {
      return this.courseEdition?.progress
    },
    edition() {
      return this.courseEdition?.course?.edition
    },
    units() {
      return this.courseEdition?.units
    },
    validations() {
      return this.courseEdition?.validations
    },
    userProgress() {
      return this.activeUser != null && this.classProgress != null
        ? this.classProgress['users'][this.activeUser.key]
        : {}
    },
    user() {
      return this.$store.getters.getUser
    },
    courseEditionEditPath() {
      return `/manage/course/${this.course?.key}/edition/${this.edition?.key}`
    },

    // Checks
    isFullLayout() {
      return this.view && ['units', 'unit', 'chats'].includes(this.view)
    },
    isMobile() {
      return window.innerWidth < 640
    },
    isCourseEditionUnlocked() {
      return (
        this.isCourseManager ||
        this.isCourseEditionManager ||
        this.isCourseEditionClassManager ||
        this.isEnrollmentDefinitive
      )
    },
    isEnrollmentDefinitive() {
      return this.courseEdition.validations?.is_definitive
    },
    isEditionRunning() {
      return this.courseEdition.validations?.is_running
    },
    isSignUpOpen() {
      return this.courseEdition.validations?.can_signup
    },

    // Permissions
    userRoles() {
      if (this.user && this.courseEdition && this.courseEdition.user && this.courseEdition.user.roles) {
        return this.courseEdition.user.roles
      }
      return []
    },

    // Course (all editions) manager
    isCourseManager() {
      for (const role of this.userRoles) {
        if (['admin'].includes(role)) return true
      }
      return false
    },
    // Edition manager (can access all classes)
    isCourseEditionManager() {
      return this.validations?.is_manager
    },
    // Class manager / leader
    isCourseEditionClassManager() {
      return this.validations?.is_leader
    },
    // Any manager role
    isManager() {
      return this.isCourseManager || this.isCourseEditionManager || this.isCourseEditionClassManager
    },
    isStudent() {
      return this.validations?.is_student
    },

    canSignUp() {
      return this.validations.can_signup
    },

    checks() {
      return {
        canSignUp: this.canSignUp,
        isEnrollmentDefinitive: this.isEnrollmentDefinitive,
        isStudent: this.isStudent,
        isManager: this.isManager,
        isCourseManager: this.isCourseManager,
        isCourseEditionManager: this.isCourseEditionManager,
        isCourseEditionClassManager: this.isCourseEditionClassManager,
        isCourseEditionUnlocked: this.isCourseEditionUnlocked,
        isEditionRunning: this.isEditionRunning
      }
    },

    //Websockets
    isWebSocketReady() {
      return !!(this.$store.state.socket && this.$store.state.socket.connectionId)
    }
  },

  watch: {
    view(newVal) {
      if (newVal) this.loadCourseEdition(true)
    }
  },

  mounted() {
    this.loadCourseEdition()
    setTimeout(() => {
      if (this.isWebSocketReady) {
        this.subscribeWs()
      }
    }, 2000)
  },

  created() {
    this.$store.dispatch('startTimer', true)
  },

  beforeDestroy() {
    this.$store.dispatch('startTimer', false)
    this.unsubscribeWs()
    this.stopTime()
  },

  methods: {
    handleWsMessages(messages) {
      // progressUpdate, topicNew, topicUpdate, topicDelete, topicAvailable, unitNew, unitUpdate, unitDelete
      console.log('messages :>> ', messages)
      if (messages.progressUpdate?.length) {
        const { global, units } = messages.progressUpdate[0]
        this.updateCourseProgress(global)
        for (const [unitKey, progress] of Object.entries(units)) {
          if (this.courseEdition?.units?.length && this.courseEdition.units.hasOwnProperty(unitKey)) {
            this.$set(this.courseEdition.units, unitKey, { ...this.courseEdition.units[unitKey], progress: progress })
          }

          if (this.activeUnit && this.activeUnit.key === unitKey) {
            this.updateUnitProgress(progress)
          }
        }
      }

      if (messages.unitPageStateUpdate?.length) {
        const { unit_key } = messages.unitPageStateUpdate[0]
        if (this.activeUnit && this.activeUnit.key === unit_key && this.$refs.panelUnit) {
          this.$refs.panelUnit.reload()
        }
      }

      if (messages.topicPageStateUpdate?.length) {
        const { unit_key } = messages.topicPageStateUpdate[0]
        if (this.activeUnit && this.activeUnit.key === unit_key && this.$refs.panelTopic) {
          this.$refs.panelTopic.reload()
        }
      }

      if (messages.unitDelete?.length) {
        const { unit_key } = messages.unitDelete[0]
        if (this.courseEdition?.units?.length) {
          const unitIndex = this.courseEdition.units.findIndex(unit => unit.key === unit_key)
          this.$delete(this.courseEdition.units, unitIndex)
          if (this.activeUnit && this.activeUnit.key === unit_key && this.view == 'unit') {
            this.activeUnit = null
            this.$router.push({ path: `/course/${this.courseKey}/edition/${this.editionKey}/unit` })
          }
        }
      }

      if (messages.unitUpdate?.length) {
        const { unit_key, data } = messages.unitUpdate[0]
        if (data.hasOwnProperty('index')) {
          this.loadCourseEdition(true)
        } else {
          if (this.courseEdition?.units?.length) {
            const unitIndex = this.courseEdition.units.findIndex(unit => unit.key === unit_key)
            this.$set(this.courseEdition.units, unitIndex, { ...this.courseEdition.units[unitIndex], ...data })
          }

          if (this.activeUnit && this.activeUnit.key === unit_key) {
            this.activeUnit = { ...this.activeUnit, ...data }
          }
        }
      }

      if (messages.unitNew?.length) {
        const { unit_key } = messages.unitNew[0]
        console.log('unitNew unit_key :>> ', unit_key)
        //if view is units, add the unitKey to params.unitKey
        if (this.activeUnit == null && this.view == 'unit') {
          this.$router.push({ path: `/course/${this.courseKey}/edition/${this.editionKey}/unit/${unit_key}` })
        }
        this.loadCourseEdition(true)
      }

      if (messages.topicAvailable?.length) {
        const { unit_key, topic_key, session } = messages.topicAvailable[0]
        if (this.activeUnit && this.activeUnit.key === unit_key) {
          let topic = this.activeUnit.topics.find(topic => topic.key === topic_key)
          if (topic) {
            topic.session = session
          }
          //this.course.units.find(unit => unit.key === unit_key).topics.find(topic => topic.key === topic_key).session = session
        }
      }

      if (messages.topicAvailabilityUpdate?.length) {
        const { unit_key, topic_key, availability } = messages.topicAvailabilityUpdate[0]
        if (this.activeUnit && this.activeUnit.key === unit_key) {
          //update list
          if (availability == 'unavailable') {
            this.activeUnit.topics.find(topic => topic.key === topic_key).session = null
          }
        }
      }

      if (messages.topicNew?.length) {
        const { unit_key, topic_key } = messages.topicNew[0]
        console.log('topicNew :>> ', unit_key, topic_key)
        this.loadCourseEdition(true)
      }

      if (messages.topicUpdate?.length) {
        const { unit_key, topic_key, data } = messages.topicUpdate[0]
        if (data.hasOwnProperty('index')) {
          this.loadCourseEdition(true)
        } else {
          if (this.activeUnit && this.activeUnit.key === unit_key) {
            const topicIndex = this.activeUnit.topics.findIndex(topic => topic.key == topic_key)
            this.$set(this.activeUnit.topics, topicIndex, {
              ...this.activeUnit.topics[topicIndex],
              ...data
            })
          }
        }
      }

      if (messages.topicDelete?.length) {
        const { unit_key, topic_key } = messages.topicDelete[0]
        if (this.activeUnit && this.activeUnit.key === unit_key) {
          let unitIndex = this.courseEdition.units.findIndex(unit => unit.key === unit_key)
          const topicIndex = this.courseEdition.units[unitIndex].topics.findIndex(topic => topic.key == topic_key)
          this.$delete(this.activeUnit.topics, topicIndex)
        }
      }
    },

    subscribeWs() {
      if (!this.wsSubscription) {
        this.wsSubscription = ServiceCourses.createEditionSubscription(this.editionKey, this.handleWsMessages)
        window.addEventListener('beforeunload', this.unsubscribeWs, {
          capture: true
        })
      }
    },

    unsubscribeWs() {
      if (this.wsSubscription) {
        this.wsSubscription.destroy()
        this.wsSubscription = null
      }
    },
    openManagement() {
      this.$router.push(`/manage/course/${this.courseKey}/edition/${this.editionKey}`)
    },
    updateCourseProgress(progress) {
      this.$set(this.courseEdition, 'progress', progress)
    },
    updateUnitProgress(progress) {
      this.$set(this.activeUnit, 'progress', progress)
    },
    closeUserDetails() {
      this.activeUser = null
    },
    openUserDetails(user) {
      this.loadClassProgress()
      console.log('openUserDetails', user)
      this.activeUser = user
    },
    async loadClassProgress() {
      this.loadingUserDetails = true
      try {
        let classKey = this.activeClassKey ?? this.courseEdition.user.class.key
        this.classProgress = await ServiceCourses.getClassProgress(this.courseKey, this.editionKey, classKey)
      } finally {
        this.loadingUserDetails = false
      }
    },
    // Control modals
    startSignup() {
      this.$buefy.dialog.confirm({
        title: 'Inscrição no espaço',
        message: 'Deseja inscrever-se e frequentar o espaço?',
        confirmText: 'Sim',
        cancelText: 'Cancelar',
        type: 'is-primary',
        hasIcon: false,
        onConfirm: () => {
          //this.openEnrollmentModal = true
          utils.tryAndCatch(
            this,
            // Main code to run
            async () => {
              const response = await ServiceCourses.signUpCourseEdition(this.courseKey, this.edition.key)
              console.log('signup :>> ', response)
              this.loadCourseEdition()
            },
            // Finally
            () => {
              this.loading = false
            }
          )
        }
      })
      //TOOD: this.openEnrollmentModal = true
    },

    closeSignupModal() {
      this.openEnrollmentModal = false
    },

    // Load and manage data
    async loadCourseEdition(noLoading = false) {
      this.loading = !noLoading

      // Grab just the data we need
      // For the next version, we may need to ask for more than one section
      let sections = [this.view || 'about']
      let subpage = this.$route.params.subpage || 'dashboard'
      console.log('loadCourseEdition', this.view, subpage)
      if ((this.view == 'unit' || subpage == 'unit') && !sections.includes('units')) {
        sections.push('units')
      }
      if (!sections.includes('about')) {
        sections.push('about')
      }

      await this.getEdition(sections)
      if (this.activeUnitKey) {
        await this.loadUnit(this.activeUnitKey)
      }

      if (sections.includes('units')) this.unitsLoaded = true

      this.loading = false
    },

    async getEdition(sections) {
      if (!this.editionKey || !this.courseKey) return
      await utils.tryAndCatch(this, async () => {
        let res = await ServiceCourses.getCourseEdition(this.courseKey, this.editionKey, sections, this.activeClassKey)
        console.log('getCourseEdition', res)
        this.courseEdition = res
        // Update remaining time to submit application
        this.remainingSeconds = res.course.edition.signup_remaining_time

        // Start clock
        if (this.remainingSeconds > 0) this.startTime()
      })
    },

    stopTime() {
      if (this.timerInterval !== null) {
        clearInterval(this.timerInterval)
        this.timerInterval = null
      }
    },

    startTime() {
      let self = this
      this.timeTick()
      if (this.timerInterval != null) {
        clearInterval(this.timerInterval)
      }
      this.timerInterval = setInterval(() => {
        self.timeTick()
      }, 1000)
    },

    timeTick() {
      this.remainingSeconds -= 1
      if (this.remainingSeconds <= 0) {
        this.stopTime()
      }
    },

    async loadUnit(unitKey) {
      console.log('loadUnit', unitKey)
      // Parse units array to object
      const units = this.courseEdition?.units?.reduce((ac, unit) => {
        return { ...ac, [unit.key]: unit }
      }, {})

      // Grab active unit from array (converted)
      if (units != null && units[unitKey]) {
        this.activeUnit = units[unitKey]
      }

      // Set default
      else if (this.courseEdition?.units?.length) {
        //this.activeUnit = this.courseEdition.units[0]
        //select the first unit with topics
        let firstUnit = this.courseEdition.units.find(unit => unit.topics && unit.topics.length)
        if (firstUnit) {
          this.activeUnit = firstUnit
        }
      }

      await utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.getCourseEditionUnit(this.courseKey, this.editionKey, unitKey, {
          collections: true
        })
        console.log('getCourseEditionUnit :>> ', response)
        this.instructors = response.managers
        this.users = response.users
        this.unitCollections = response.collections
      })
    },

    changeClass(classKey) {
      this.$router.push({ query: { class_key: classKey } })
      this.goToView('dashboard', null, true)
    },

    // Manage views
    goToView(view, unitKey = null, forceReload = false) {
      console.log('view, unitKey :>> ', view, unitKey)
      let baseUrl = `/course/${this.courseKey}/edition/${this.editionKey}/${view}`

      if (!unitKey) {
        unitKey = this.$route.params.unitKey || null
      }
      console.log('unitKey :>> ', unitKey)

      // Set active unit key
      if (view == 'unit' && unitKey) {
        this.loadUnit(unitKey)
        baseUrl = `${baseUrl}/${unitKey}`
      }

      // Grab first unit
      else if (view == 'units') {
        if (this.courseEdition?.units?.length) {
          //get the first module with topics
          let firstUnit = this.courseEdition.units.find(unit => unit.topics && unit.topics.length)
          if (firstUnit) {
            this.loadUnit(firstUnit.key)
            baseUrl = `${baseUrl}/${firstUnit.key}`
          }
        }
      }

      if (this.$router.currentRoute.path !== baseUrl) {
        // Set active class key for edition managers
        if (this.activeClassKey) {
          baseUrl = baseUrl + `?class_key=${this.activeClassKey}`
        }
        console.log('goToView :>> ', baseUrl)
        this.$router.push({ path: baseUrl })
      }

      if (forceReload) {
        this.$router.go()
      }

      // Update view name
      // this.view = view

      // this.loadCourseEdition(true)
    }
  }
}
</script>
