<template>
  <div class="relative">
    <LoadingPlaceholder v-if="loading" />

    <div v-else>
      <fw-panel :title="$t('academic_plan')" boxed="lg" featured></fw-panel>

      <fw-panel :title="'Metadados'" boxed="lg" class="mb-5" custom-class="bg-white flex flex-col gap-3">
        <template #toolbar>
          <div class="flex gap-1 items-center">
            <div v-if="$v.$error" class="p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-medium">
              <fw-icon-error-warning class="w-6 h-6"></fw-icon-error-warning>
              <span class="hidden lg:block">{{ $t('thereAreErrors') }}</span>
            </div>
            <div class="flex gap-5">
              <fw-button
                :type="isDirty ? 'primary' : 'light'"
                :disabled="savingData"
                :loading="savingData"
                @click.native="savePortfolio()"
                >{{ $t('save') }}</fw-button
              >
            </div>
          </div>
        </template>
        <div>
          <fw-label v-if="!portfolio.options.hasEnglish">{{ $t('description.label') }}</fw-label>
          <div v-if="portfolio.options.hasEnglish">
            <fw-label>{{ $t('description.pt') }}</fw-label>
            <fw-editor v-model="description['pt']" @input="isDirty = true"></fw-editor>
            <fw-label>{{ $t('description.en') }}</fw-label>
            <fw-editor v-model="description['en']" @input="isDirty = true"></fw-editor>
          </div>
          <fw-editor v-else v-model="description.pt" @input="isDirty = true"></fw-editor>
          <fw-tip v-if="$v.description.$error" error>
            {{ $t('description.required') }}
          </fw-tip>
        </div>

        <div class="hidden">
          <fw-label>{{ $t('files.label') }}</fw-label>
          <div v-if="portfolio.academic_plan.files && portfolio.academic_plan.files.length > 0" class="files mb-1.5">
            <RecordFileEntry
              v-for="(file, f) in portfolio.academic_plan.files"
              :key="file.key"
              :can-edit="true"
              :allow-classified="false"
              :file="file"
              @save="saveFile(f, $event)"
              @remove="removeFile(f)"
              @download="downloadFile(file)"
            />
          </div>
          <Uploader
            :label="$t('files.upload')"
            :is-docked="true"
            layout="minimal"
            reference-id="uploader_update"
            allowed="all"
            :clear-after="true"
            input-id="upload_input"
            :files.sync="updateFilesToUpload"
            :size="0"
            :new-file-context="{}"
            file-type="file"
            file-code=""
            class="cursor-pointer update-modal-uploader"
            uploader-class="w-full rounded"
            @upload="uploaded"
          />
        </div>
      </fw-panel>

      <fw-panel :title="'Atividades académicas'" boxed="lg" class="mb-5" custom-class="bg-white flex flex-col gap-3">
        <div class="flex flex-col gap-5">
          <div v-for="(academic_year, a) in academic_years" :key="'academic_' + a">
            <div class="flex justify-between">
              <div class="rounded-full flex px-4 py-1.5 font-bold text-white bg-primary gap-3 items-center">
                {{ academic_year.name[language] }}
              </div>
              <b-dropdown aria-role="list" position="is-bottom-left">
                <template #trigger="{ active }">
                  <fw-button type="white" size="sm"> <fw-icon-more class="w-5 h-5 text-gray-500"/></fw-button>
                </template>
                <b-dropdown-item paddingless aria-role="listitem">
                  <fw-button type="white-gray" class="w-full text-left" @click.native="editYear(a)">{{
                    $t('edit')
                  }}</fw-button>
                </b-dropdown-item>
                <b-dropdown-item paddingless aria-role="listitem">
                  <fw-button type="white-gray" class="w-full text-left" @click.native="removeAcademicYear(a)">{{
                    $t('delete_year')
                  }}</fw-button>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="pl-5 pt-5 flex flex-col">
              <div class="portfolio-label">{{ $t('curriculum_activities') }}</div>
              <div class="flex flex-col gap-5 mb-2">
                <div
                  v-for="(item, i) in academic_year.curriculum"
                  :key="'academicitem' + a + i"
                  class="py-2 flex flex-col gap-3 border-b border-gray-200"
                >
                  <div class="flex items-center">
                    <div
                      class="flex-1 text-lg font-bold flex items-center gap-3"
                      :class="{
                        'text-gray-500': !item.completed,
                        'text-black': item.completed
                      }"
                    >
                      {{ item.name && item.name[language] ? item.name[language] : item }}
                    </div>
                    <b-dropdown aria-role="list" position="is-bottom-left">
                      <template #trigger="{ active }">
                        <fw-button type="white" size="sm"> <fw-icon-more class="w-5 h-5 text-gray-500"/></fw-button>
                      </template>
                      <b-dropdown-item paddingless aria-role="listitem">
                        <fw-button type="white-gray" class="w-full text-left" @click.native="editActivity(a, i)">{{
                          $t('edit')
                        }}</fw-button>
                      </b-dropdown-item>
                      <b-dropdown-item paddingless aria-role="listitem">
                        <fw-button type="white-gray" class="w-full text-left" @click.native="removeActivity(a, i)">{{
                          $t('remove_activity')
                        }}</fw-button>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div class=" flex flex-col">
                    <fw-label>{{
                      item.completed ? $t('developed_comeptences') : $t('developing_comeptences')
                    }}</fw-label>

                    <div class="flex flex-wrap items-center gap-3">
                      <div
                        v-for="(competence, c) in item.competences"
                        :key="'academicitem' + a + i + c"
                        class="bg-gray-100 rounded-full pl-5 pr-3 py-1 font-semibold flex items-center cursor-pointer"
                        :class="{ 'text-primary': item.completed }"
                        @click="removeCompetence(a, i, c)"
                      >
                        {{ competence.name && competence.name[language] ? competence.name[language] : competence }}
                        <fw-icon-close class=" h-5 w-5 text-gray-400"></fw-icon-close>
                      </div>
                      <div
                        class=" bg-primary hover:opacity-80 cursor-pointer rounded-full font-semibold px-5 py-1 text-white"
                        @click="addCompetence(a, i)"
                      >
                        {{ $t('add_competence') }}
                      </div>
                    </div>
                  </div>
                  <div class=" flex flex-col">
                    <fw-label>{{ $t('activity_state') }}</fw-label>
                    <b-switch v-model="item.completed" @input="savePortfolio(false)">
                      {{ $t('completed_activity') }}
                    </b-switch>
                  </div>
                </div>
                <div v-if="academic_year.curriculum.length == 0" class="text-sm py-5 text-center text-gray-500">
                  {{ $t('no_activity') }}
                </div>
              </div>
              <fw-button type="light" expanded class="" @click.native="addCurriculumItem(a)">{{
                $t('add_curriculum_activity')
              }}</fw-button>
            </div>

            <div class="pl-5 pt-5 flex flex-col">
              <div class="portfolio-label">{{ $t('extra_curriculum_activities') }}</div>
              <div class="flex flex-col gap-5 mb-3">
                <div
                  v-for="(item, i) in academic_year.extra_curriculum"
                  :key="'extraacademicitem' + a + i"
                  class="py-2 flex flex-col gap-3 border-b border-gray-200"
                >
                  <div class="flex items-center">
                    <div
                      class="flex-1 text-lg font-bold"
                      :class="{
                        'text-gray-500': !item.completed,
                        'text-black': item.completed
                      }"
                    >
                      {{ item.name && item.name[language] ? item.name[language] : item }}
                    </div>
                    <b-dropdown aria-role="list" position="is-bottom-left">
                      <template #trigger="{ active }">
                        <fw-button type="white" size="sm"> <fw-icon-more class="w-5 h-5 text-gray-500"/></fw-button>
                      </template>
                      <b-dropdown-item paddingless aria-role="listitem">
                        <fw-button
                          type="white-gray"
                          class="w-full text-left"
                          @click.native="editActivity(a, i, 'extra_curriculum')"
                          >{{ $t('edit') }}</fw-button
                        >
                      </b-dropdown-item>
                      <b-dropdown-item paddingless aria-role="listitem">
                        <fw-button
                          type="white-gray"
                          class="w-full text-left"
                          @click.native="removeActivity(a, i, 'extra_curriculum')"
                          >{{ $t('remove_activity') }}</fw-button
                        >
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div class="flex flex-col">
                    <fw-label>{{
                      item.completed ? 'Competências desenvolvidas' : 'Competências a desenvolver'
                    }}</fw-label>
                    <div class="flex flex-wrap items-center gap-3">
                      <div
                        v-for="(competence, c) in item.competences"
                        :key="'extraacademicitem' + a + i + c"
                        class="bg-gray-100 rounded-full pl-5 pr-3 py-1 font-semibold flex items-center cursor-pointer"
                        :class="{ 'text-primary': item.completed }"
                        @click="removeCompetence(a, i, c, 'extra_curriculum')"
                      >
                        {{ competence.name && competence.name[language] ? competence.name[language] : competence }}
                        <fw-icon-close class=" h-5 w-5 text-gray-400"></fw-icon-close>
                      </div>
                      <div
                        class=" bg-primary hover:opacity-80 cursor-pointer rounded-full font-semibold px-5 py-1 text-white"
                        @click="addCompetence(a, i, 'extra_curriculum')"
                      >
                        {{ $t('add_competence') }}
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col">
                    <fw-label>Estado da atividade</fw-label>
                    <b-switch v-model="item.completed" @input="savePortfolio(false)">
                      {{ $t('activity_state') }}
                    </b-switch>
                  </div>
                  <div v-if="academic_year.extra_curriculum.length == 0" class="text-sm py-5 text-center text-gray-500">
                    {{ $t('no_extracurriculum_activities') }}
                  </div>
                </div>
                <div v-if="academic_year.extra_curriculum.length == 0" class="text-sm py-5 text-center text-gray-500">
                  {{ $t('no_activity') }}
                </div>
              </div>
              <fw-button type="light" expanded class="" @click.native="addCurriculumItem(a, 'extra_curriculum')">
                {{ $t('add_extra_curriculum_activity') }}
              </fw-button>
            </div>
          </div>
        </div>
        <fw-button type="primary" class="text-center" @click.native="addAcademicYear">{{
          $t('add_academic_year')
        }}</fw-button>
      </fw-panel>

      <ModalCompetence ref="modalcompetence" @competence="createCompetence" />
      <ModalMultilanguageEdit ref="modalyearedit" @save="saveYear" />
      <ModalAcademicActivity ref="modalactivity" @save="saveActivity" />
    </div>
  </div>
</template>

<script>
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'

import Uploader from '@/fw-modules/fw-core-vue/storage/components/PathUploader.vue'
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import ModalCompetence from '@/components/modals/ModalCompetence'
import ModalMultilanguageEdit from '@/components/modals/ModalMultilanguageEdit'
import ModalAcademicActivity from '@/components/modals/ModalAcademicActivity'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  components: {
    LoadingPlaceholder,
    RecordFileEntry,
    Uploader,
    ModalCompetence,
    ModalMultilanguageEdit,
    ModalAcademicActivity
  },

  props: {
    portfolio: {
      type: Object,
      default: () => {
        return {}
      }
    },

    savingData: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isDirty: false,
      updateFilesToUpload: [],
      pointer: {
        a: 0,
        i: 0,
        type: 'curriculum'
      },
      description: {}
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    language() {
      return this.$store.state.language
    },

    secondaryLanguage() {
      return this.language === 'pt' ? 'en' : 'pt'
    },
    academic_years() {
      return this.portfolio.academic_plan.items
    }
  },

  mounted() {
    this.description = this.portfolio.academic_plan.description
  },

  validations() {
    return {
      description: {
        pt: { required, min: minLength(1) }
      }
    }
  },

  methods: {
    editYear(i) {
      this.pointer.a = i
      this.$refs.modalyearedit.edit(this.academic_years[i].name)
    },
    orderAcademicYears() {
      //order academic years by name
      this.academic_years.sort((a, b) => {
        if (a.name.pt < b.name.pt) {
          return -1
        }
        if (a.name.pt > b.name.pt) {
          return 1
        }
        return 0
      })
    },
    saveYear(data) {
      this.academic_years[this.pointer.a].name = data
      this.orderAcademicYears()
      this.savePortfolio(false)
    },
    editActivity(a, i, type = 'curriculum') {
      this.pointer.a = a
      this.pointer.i = i
      this.pointer.type = type
      this.$refs.modalactivity.edit(this.academic_years[a][type][i].name)
    },
    removeActivity(a, i, type = 'curriculum') {
      this.$buefy.dialog.confirm({
        title: this.language == 'pt' ? 'Remover atividade' : 'Remove activity',
        cancelText: this.language == 'pt' ? 'Cancelar' : 'Cancel',
        confirmText: this.language == 'pt' ? 'Remover' : 'Remove',
        message:
          this.language == 'pt'
            ? 'Tem a certeza que deseja remover esta atividade? Vai remover todas as competências associadas.'
            : 'Are you sure you want to remove this activity? It will remove all associated competences.',
        onConfirm: () => {
          this.academic_years[a][type].splice(i, 1)
          this.savePortfolio(false)
        }
      })
    },
    removeAcademicYear(a) {
      //if (this.academic_years[a].curriculum.length == 0 && this.academic_years[a].extra_curriculum.length == 0) {
      //this.academic_years.splice(a, 1)
      //} else {
      this.$buefy.dialog.confirm({
        title: this.language == 'pt' ? 'Remover ano académico' : 'Remove academic year',
        cancelText: this.language == 'pt' ? 'Cancelar' : 'Cancel',
        confirmText: this.language == 'pt' ? 'Remover' : 'Remove',
        message:
          this.language == 'pt'
            ? 'Tem a certeza que deseja remover este ano académico? Vai remover todos os items e competências associadas.'
            : 'Are you sure you want to remove this academic year? It will remove all associated items and competences.',
        onConfirm: () => {
          this.academic_years.splice(a, 1)
          this.savePortfolio(false)
        }
      })
      //}
    },
    removeCompetence(a, i, c, type = 'curriculum') {
      this.$buefy.dialog.confirm({
        title: this.language == 'pt' ? 'Remover competência' : 'Remove competence',
        cancelText: this.language == 'pt' ? 'Cancelar' : 'Cancel',
        confirmText: this.language == 'pt' ? 'Remover' : 'Remove',
        message:
          this.language == 'pt'
            ? 'Tem a certeza que deseja remover esta competência.'
            : 'Are you sure you want to remove this competence?',
        onConfirm: () => {
          this.academic_years[a][type][i].competences.splice(c, 1)
          this.savePortfolio(false)
        }
      })
    },
    addAcademicYear() {
      let year = this.academic_years.length + 1
      this.academic_years.push({
        name: {
          pt: year + 'º ano',
          en: year + (year == 1 ? 'st' : year == 2 ? 'nd' : year == 2 ? 'rd' : 'th') + ' year'
        },
        curriculum: [],
        extra_curriculum: [],
        autoevaluation: {
          pt: '',
          en: ''
        }
      })
      this.orderAcademicYears()
      this.savePortfolio(false)
    },
    addCurriculumItem(a, type = 'curriculum') {
      this.pointer.a = a
      this.pointer.type = type
      this.$refs.modalactivity.edit()
    },
    addCompetence(a, i, type = 'curriculum') {
      this.pointer.a = a
      this.pointer.i = i
      this.pointer.type = type
      this.$refs.modalcompetence.show()
    },
    saveActivity(data) {
      if (data.editing) {
        this.academic_years[this.pointer.a][this.pointer.type][this.pointer.i].name = data.data
      } else {
        this.academic_years[this.pointer.a][this.pointer.type].push({
          name: data.data,
          competences: [],
          completed: false
        })
      }
      this.savePortfolio(false)
    },
    async uploaded(files) {
      let newfiles = []
      for (const file of files) {
        if (file.response.data) {
          if (file.response.status === 'success') {
            newfiles.push(file.response.data.file)
          }
        }
      }

      this.task.files = this.task.files.concat(newfiles)
    },

    async downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },

    removeFile(f) {
      this.$buefy.dialog.confirm({
        message: this.$t('deleteFileConfirm'),
        onConfirm: async () => {
          this.task.files.splice(f, 1)
        }
      })
    },

    saveFile(f, updatedFile) {
      console.log('save filename', f, updatedFile)
      this.$set(this.task.files, f, { filename: updatedFile.title, key: updatedFile.key })
    },
    remove(i) {
      this.competences.splice(i, 1)
    },
    onDropSection(event) {
      console.log(event)
      let previousIndex = event.removedIndex
      this.$emit('change-order', event)
      console.log('previousIndex', previousIndex, event)
    },
    getChildPayload(index) {
      return this.competences[index]
    },
    savePortfolio(saveMetadata = true) {
      //this.$v.portfolio.$touch()
      //if (this.$v.portfolio.$invalid) return
      let portfolio = JSON.parse(JSON.stringify(this.portfolio))
      if (saveMetadata) {
        portfolio.academic_plan.description = this.description
        this.isDirty = false
      }
      //replace competences by its key
      portfolio.academic_plan.items.forEach((item, i) => {
        item.curriculum.forEach((curriculum, c) => {
          portfolio.academic_plan.items[i].curriculum[c].competences.forEach((competence, cp) => {
            portfolio.academic_plan.items[i].curriculum[c].competences[cp] = competence.key
          })
        })

        item.extra_curriculum.forEach((extra_curriculum, c) => {
          portfolio.academic_plan.items[i].extra_curriculum[c].competences.forEach((competence, cp) => {
            portfolio.academic_plan.items[i].extra_curriculum[c].competences[cp] = competence.key
          })
        })
      })

      //replace competences with keys
      portfolio.carrer_development.professional_experience = portfolio.carrer_development.professional_experience.map(
        item => {
          item.competences = item.competences.map(competence => competence.key)
          return item
        }
      )
      portfolio.carrer_development.volunteering = portfolio.carrer_development.volunteering.map(item => {
        item.competences = item.competences.map(competence => competence.key)
        return item
      })
      portfolio.carrer_development.certifications = portfolio.carrer_development.certifications.map(item => {
        item.competences = item.competences.map(competence => competence.key)
        return item
      })

      this.$emit('save-portfolio', portfolio)
    },

    createCompetence(competence) {
      //add competence if it doesn't exist
      let found = this.academic_years[this.pointer.a][this.pointer.type][this.pointer.i].competences.find(
        c => c.key == competence.key
      )
      if (!found) {
        this.academic_years[this.pointer.a][this.pointer.type][this.pointer.i].competences.push(competence)
        this.savePortfolio(false)
      }
    },

    publish(publish = true) {
      this.$buefy.dialog.confirm({
        title: publish ? `Publicar torneio` : 'Despublicar torneio',
        cancelText: 'Cancelar',
        confirmText: publish ? 'Publicar' : 'Despublicar',
        message: publish
          ? `Tem a certeza que deseja publicar este torneio?`
          : 'Tem a certeza que deseja despublicar este torneio?',
        onConfirm: () => {
          this.$emit('publish-portfolio', publish)
        }
      })
    }
  }
}
</script>
<i18n>
{
  "pt": {
    "completed_activity": "Atividade realizada",
    "add_curriculum_activity": "Adicionar atividade curricular",
    "add_extra_curriculum_activity": "Adicionar atividade extra-curricular",
    "extra_curriculum_activities": "Atividades extra-curriculares",
    "developed_comeptences": "Competências desenvolvidas",
    "add_academic_year": "Adicionar ano académico",
    "developing_comeptences": "Competências a desenvolver",
    "no_activity": "Sem atividades associadas",
    "no_extracurriculum_activities": "Sem atividades extra-curriculares associadas",
    "activity_state": "Estado da atividade",
    "delete_year": "Remover ano",
    "curriculum_activities": "Atividades curriculares",
    "add_competence": "+ Adicionar competência",
    "remove_activity": "Remover atividade",
    "deleteFileConfirm": "Tem a certeza que deseja remover este ficheiro?",
    "academic": {
      "pt": "Nome da atividade académica em Português",
      "en": "Nome da atividade académica em Inglês",
      "required": "Insira o nome da atividade académica em Português e Inglês."
    },
    "academic_plan": "Plano académico",
    "files": {
      "label": "Ficheiros",
      "upload": "Carregar ficheiros"
    },
    "aboutme": "Sobre mim",
    "competences": "Competências",
    "settings": "Configurações",
    "thereAreErrors": "Existem erros no formulário",
    "close": "Fechar",
    "edit": "Editar",
    "save": "Guardar",
    "delete": "Remover",
    "personalInfo": "Informação pessoal",
    "tournamentTitle": "Título",
    "competence": {
      "pt": "Nome da competência em Português",
      "en": "Nome da competência em Inglês",
      "required": "Insira o nome da competência em Português e Inglês."
    },
    "description": {
      "label": "Descrição",
      "pt": "Descrição em Português",
      "en": "Descrição em Inglês",
      "required": "Insira uma descrição em Português e Inglês."
    },
    "interests": {
      "label": "Interesses",
      "pt": "Interesses em Português",
      "en": "Interesses em Inglês",
      "required": "Insira os seus interesses em Português e Inglês."
    },
    "hobbies": {
      "label": "Hobbies",
      "pt": "Hobbies em Português",
      "en": "Hobbies em Inglês",
      "required": "Insira os seus hobbies em Português e Inglês."
    },
    "shortdescription": {
      "label": "Descrição curta",
      "pt": "Descrição curta em Português",
      "en": "Descrição curta em Inglês",
      "required": "Insira uma descrição curta em Português e Inglês."
    },
    "charsLimit": "Limite de {limit} caracteres.",
    "notDefined": "Não definido"
  },
  "en": {
    "developed_comeptences": "Developed competences",
    "developing_comeptences": "Developing competences",
    "add_extra_curriculum_activity": "Add extra-curricular activity",
    "no_extracurriculum_activities": "No extra-curricular activities associated",
    "extra_curriculum_activities": "Extra-curricular activities",
    "no_activity": "No activities associated",
    "add_curriculum_activity": "Add curriculum activity",
    "add_academic_year": "Add academic year",
    "completed_activity": "Completed activity",
    "activity_state": "Activity state",
    "add_competence": "+ Add competence",
    "remove_activity": "Remove activity",
    "delete_year": "Delete year",
    "curriculum_activities": "Curriculum activities",
    "deleteFileConfirm": "Are you sure you want to remove this file?",
    "academic": {
      "pt": "Name of the academic activity in Portuguese",
      "en": "Name of the academic activity in English",
      "required": "Enter the name of the academic activity in English and Portuguese"
    },
    "academic_plan": "Academic plan",
    "files": {
      "label": "Files",
      "upload": "Upload files"
    },
    "aboutme": "About me",
    "competences": "Competences",
    "settings": "Settings",
    "generalDetails": "General details",
    "tournamentTitle": "Title",
    "thereAreErrors": "There are errors in the form",
    "personalInfo": "Personal info",
    "close": "Close",
    "edit": "Edit",
    "save": "Save",
    "delete": "Remove",
    "competence": {
      "pt": "Name of the competence in Portuguese",
      "en": "Name of the competence in English",
      "required": "Insert the name of the competence in Portuguese and English"
    },
    "description": {
      "label": "Description",
      "pt": "Description in Portuguese",
      "en": "Description in English",
      "required": "Enter a description in English and Portuguese."
    },
    "interests": {
      "label": "Interests",
      "pt": "Interests in Portuguese",
      "en": "Interests in English",
      "required": "Enter your interests in English and Portuguese."
    },
    "hobbies": {
      "label": "Hobbies",
      "pt": "Hobbies in Portuguese",
      "en": "Hobbies in English",
      "required": "Enter your hobbies in English and Portuguese."
    },
    "shortdescription": {
      "label": "Short description",
      "pt": "Short description in Portuguese",
      "en": "Short description in English",
      "required": "Enter a short description in English and Portuguese."
    },
    "charsLimit": "Limit of {limit} characters.",
    "notDefined": "Not defined"
  }
}
</i18n>

<style>
.drag-handle {
  @apply h-6 w-5 shadow-sm absolute top-0.5 left-1 bg-white rounded border border-gray-200 items-center flex justify-center text-center;
  cursor: grab;
}
.update-modal-uploader .file-uploads {
  @apply w-full;
}
.aboutme-title {
  @apply text-primary font-semibold text-lg;
}
.portfolio-label {
  @apply font-semibold text-gray-500;
}
.portfolio-completed {
  @apply bg-primary text-sm rounded-full text-white px-3 py-0.5 font-semibold flex items-center justify-center;
}
</style>
